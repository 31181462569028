<template>
  <!-- 订单支付页面 -->
  <div v-if="!isPeiSongDan" v-loading.fullscreen.lock="payLoading" class="page">
    <Dialog ref="dialog" :config="config" />
    <HomePageTop />

    <stepShowVue></stepShowVue>
    <Paymentuseragreement :Userprotocol="protocol" @clonseUserprotocol="clonseUserprotocol"></Paymentuseragreement>
    <div class="payStep1 payPage" v-if="step == 1">
      <div class="payLeftCon">
        <div class="addressBox">
          <div class="linkman shouhuo">
            <h1>
              <span>
                {{ $fanyi('收货地址') }}
              </span>
              <el-select class="diZhiXuanZe" v-model="actNum.shouhuodizhi" @change="findValue('shouhuodizhi')"
                :placeholder="addressselect">
                <el-option v-for="(additem, ind) in userAddressList.shouhuodizhi" :key="additem.id"
                  :label="userAddress + (ind + 1)" :value="additem.id">
                </el-option>
              </el-select>
            </h1>
            <dizhiForm ref="dizhiref" :shouhuodizhi="shouhuodizhi" :key="timer"></dizhiForm>
          </div>
        </div>

        <div class="goodsBox margin" :class="{ GoodsArrAll: showGoodsArrAll }">
          <div class="dingdan">
            <h1>{{ $fanyi('订单摘要') }}</h1>
            <span class="goodsBoxtishi">{{ $fanyi('订单号') }}: &emsp;{{ $route.query.order_sn }}</span>
          </div>
          <div class="goodsArr">
            <div class="goodsHead goodsTr">
              <div class="goodsTd goodshe goodsName">
                {{ $fanyi('产品') }}
              </div>
              <div class="goodsTd goodshe goodsNumber">
                {{ $fanyi('购买数量') }}
              </div>
              <div class="goodsTd goodshe goodsPrice">
                {{ $fanyi('预计到货成本') }}
              </div>
            </div>

            <div class="goodsOpt goodsTr" v-for="goodsItem in buyGoodsArr" :key="'good' + goodsItem.id">
              <div class="goodsTd goodsBo goodsName">
                <el-popover placement="right" trigger="hover">
                  <img :src="goodsItem.pic" alt="" style="width: 300px; height: 300px" />

                  <el-image slot="reference" :src="goodsItem.pic">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                    <div slot="placeholder">
                      <i class="el-icon-loading"></i>
                    </div>
                  </el-image>
                </el-popover>
                <el-popover placement="bottom" trigger="hover" width="800">
                  {{ goodsItem.goods_title }}
                  <span slot="reference" class="goodsTitle">
                    {{ goodsItem.goods_title }}
                  </span>
                </el-popover>
              </div>
              <div class="goodsTd goodsBo goodsNumber" style="line-height: 85px">
                {{ goodsItem.confirm_num }}
              </div>
              <div class="goodsTd goodsBo goodsPrice">
                <p>
                  ${{
                    goodsItem.confirm_num *
                      goodsItem.confirm_price *
                      goodsdatas.exchange_rate >
                      0 &&
                      goodsItem.confirm_num *
                      goodsItem.confirm_price *
                      goodsdatas.exchange_rate < 0.01 ? 0.01 : $fun.RMBNumSegmentation($fun.ceil(goodsItem.confirm_num *
                        goodsItem.confirm_price * goodsdatas.exchange_rate)) }} </p>
                    <p>
                      ({{
                        $fun.RMBNumSegmentation(
                          $fun.ceil(goodsItem.china_amount.list.china_goods_amount)
                        )
                      }}￥)
                    </p>
              </div>
            </div>
          </div>
          <div class="showAllRow">
            <img class="jiahao" src="../../assets/pulldown_1.svg" alt="" v-if="!showGoodsArrAll"
              @click="showGoodsArrAll = !showGoodsArrAll" />
            <img class="jiahao" src="../../assets/pulldown_0.svg" alt="" v-else
              @click="showGoodsArrAll = !showGoodsArrAll" />
          </div>
        </div>
      </div>
      <!-- 订单右侧支付 -->
      <div class="payRightCon">
        <!--! 余额支付 余额大于0 并且余额减去订单金额大于等于零显示余额支付 -->
        <!-- "" -->

        <div class="balance" v-if="balance > 0 && balance - payAllCNPrice >= 0">
          <div class="top-title">
            {{ $fanyi('余额支付') }}
            <span></span>
          </div>
          <!-- 订单金额 -->
          <div class="oeder-box" style="border-bottom: 1px dashed #e2e2e2">
            <div class="order">
              <span>{{ $fanyi('订单金额') }}</span>
              <span>${{ $fun.EURNumSegmentation(payAll) }}</span>
            </div>
            <div class="jine">
              <span>{{ $fanyi('应付款金额') }}</span>
              <span> $ {{ $fun.EURNumSegmentation(payAllCNPrice) }}</span>
            </div>
          </div>
          <div class="oeder-box" style="border-bottom: 1px solid #222222">
            <div class="useblane">
              <span>{{ $fanyi('账户余额') }}</span>
              <span>$ {{ $fun.EURNumSegmentation(balance) }}</span>
            </div>
            <div class="paymentblane">
              <span>{{ $fanyi('付款后余额') }}</span>
              <span>
                $
                {{
                  $fun.EURNumSegmentation((balance - payAllCNPrice).toFixed(2))
                }}</span>
            </div>
          </div>
          <div class="balancePayBtn" @click="blanepayment">
            {{ $fanyi('支付') }}
          </div>
          <div class="bankPayAgreementContainer">
            <el-checkbox v-model="balancePayAgreementChecked"></el-checkbox>
            <p @click="Userprotocol">
              {{ $fanyi('我知道并同意本网站的') }}
              <span>{{ $fanyi('隐私政策条款和条件') }}</span>
            </p>
          </div>
        </div>
        <!-- ? 银行卡 支付  -->
        <div class="debitcard" v-if="balance < 0 || balance - payAllCNPrice < 0">
          <div class="payTypeChiose">
            <div class="allPayType">
              <div @click="payType = 2" :class="{ active: payType == 2 }">
                <img style="margin-bottom: 6px;" src="../../assets/payyment/paypal.svg" alt="" />
              </div>
              <!-- @click="payType = 0" -->
              <div :class="{ active: payType == 0 }">
                <el-popover ref="bankTipRef" placement="bottom" width="225" trigger="hover">
                  <div class="Bankingmode">
                    <p @click="closePopover('ACH')">ACH</p>
                    <p @click="closePopover('Fedwire')">Fedwire</p>
                  </div>
                  <p slot="reference">
                    <img src="../../assets/payyment/xt.svg" style="margin-right: 5px;" alt="" />
                    {{ bank }}
                    <span style="
                    font-size: 16px;font-weight: 600 " v-if="Bankingmode">{{ '(' + Bankingmode + ')' }}</span>
                  </p>
                </el-popover>
              </div>
              <!-- <div @click="payType = 1" :class="{ active: payType == 1 }">
                {{ $fanyi('PingPong') }}
              </div> -->
            </div>
            <div class="chioseBlock" :class="{
              xinyongka: payType == 1,
              paypal: payType == 2,
              zaixianzhufu: payType == 0,
            }"></div>
          </div>

          <!-- 银行卡支付 payType == 0-->
          <div v-if="payType == 0" class="Rabobank">
            <div class="top-box">
              <div class="useblane">
                <span>{{ $fanyi('账户余额') }}</span>
                <span>$ {{ $fun.EURNumSegmentation(balance) }}</span>
              </div>
              <div class="Lowest">
                <span>{{ $fanyi('最低入金金额') }}</span><span>
                  ${{
                    balance > 0 && balance - payAllCNPrice > 0
                      ? $fun.EURNumSegmentation(payAllCNPrice)
                      : datas.min_bank_pay_amount
                  }}</span>
              </div>
            </div>
            <!-- 银行卡信息区域 -->
            <div class="content">
              <div class="pic-box">
                <img v-if="Bankingmode == 'Fedwire'" src="../../assets/payyment/JPMorgan-logo.png" alt="" />
                <img v-if="Bankingmode == 'ACH'" src="../../assets/payyment/cfsb.png" alt="" />
              </div>
              <!-- 收件人银行信息 -->
              <!-- 弹窗如何运作的 -->
              <el-dialog :title="$fanyi('它是如何运作的?')" :visible.sync="showOperate" width="30%">
                <div class="operationshow">
                  <div class="left">
                    <div class="one">1</div>
                    <span></span>
                    <div class="two">2</div>
                  </div>
                  <div class="right">
                    <div class="box">
                      <h3>{{ $fanyi('银行转账') }}</h3>
                      <p>{{ $fanyi('将您订单中需花费的钱转账到我们账户') }}</p>
                    </div>
                    <div class="box" style="margin: 0px">
                      <h3>{{ $fanyi('等待处理') }}</h3>
                      <p>{{ $fanyi('等待1至2个工作日处理订单.') }}</p>
                    </div>
                  </div>
                </div>
                <span slot="footer" class="showOperate-footer">
                  <el-button type="primary" class="queren" @click="showOperate = false">{{ $fanyi('确认') }}</el-button>
                </span>
              </el-dialog>
              <div class="Attninfo">
                <h2>{{ $fanyi('收件人银行信息') }}</h2>
                <p>
                  {{
                    $fanyi(
                      '使用以下银行数据通过您的在线银行或移动应用程序进行转账，订单处理后，将进行购买。'
                    )
                  }}
                </p>
                <div class="operation" @click="showOperate = true">
                  <img src="../../assets/icon/why.svg" alt="" />
                  <span>{{ $fanyi('它是如何运作的?') }}</span>
                </div>
                <h2 style="margin-bottom: 20px">
                  {{ $fanyi('使用订单号作为付款概念/参考') }}
                </h2>
                <div class="ordersn">
                  <img src="../../assets/order.svg" alt="" />
                  <span>
                    {{
                      $route.query.order_sn
                        ? $route.query.order_sn
                        : $route.query.porder_sn
                    }}</span>
                </div>

                <template v-if="Bankingmode == 'Fedwire'">
                  <p class="title">{{ $fanyi('账号名称') }}</p>
                  <p class="text">
                    Sameway Global Trade Investment Limited
                    <img @click="Copytheaccountname($route.query.order_sn)" src="../../assets/payyment/copy.svg"
                      alt="" />
                  </p>

                  <p class="title">{{ $fanyi('银行账户') }}</p>
                  <p class="text">
                    20000011884118
                    <img @click="copyTextToClipboard" src="../../assets/payyment/copy.svg" alt="" />
                  </p>

                  <p class="title">{{ $fanyi('银行名称') }}</p>
                  <p class="text">
                    JPMORGAN CHASE BANK, N.A
                    <img @click="copyTextToClipboard" src="../../assets/payyment/copy.svg" alt="" />
                  </p>

                  <p class="title">ABA/ Routing Number：</p>
                  <p class="text">
                    021000021
                    <img @click="copyTextToClipboard" src="../../assets/payyment/copy.svg" alt="" />
                  </p>

                  <p class="title">{{ $fanyi('银行地址') }}</p>
                  <p class="text">
                    JPMORGAN CHASE BANK, N.A. COLUMBUS,OH 43240 COLUMBUS,OH US
                    UNITED STATES OF AMERICA
                    <img @click="copyTextToClipboard" src="../../assets/payyment/copy.svg" alt="" />
                  </p>
                  <p style="
                  font-family: PingFangSC, PingFang SC;
font-weight: 400;
font-size: 14px;
color: #999999;
line-height: 22px;">
                    Remark：<br />When you make an online bank Transfer, please include our full account name in the
                    remarks: Sameway Global Trade Investment Limited.
                  </p>
                </template>
                <template v-if="Bankingmode == 'ACH'">
                  <p class="title">{{ $fanyi('账号名称') }}</p>
                  <p class="text">
                    Sameway Global Trade Investment Limited
                    <img @click="Copytheaccountname($route.query.order_sn)" src="../../assets/payyment/copy.svg"
                      alt="" />
                  </p>

                  <p class="title">{{ $fanyi('银行账户') }}</p>
                  <p class="text">
                    8338401008
                    <img @click="copyTextToClipboard" src="../../assets/payyment/copy.svg" alt="" />
                  </p>

                  <p class="title">{{ $fanyi('银行名称') }}</p>
                  <p class="text">
                    Community Federal Savings Bank
                    <img @click="copyTextToClipboard" src="../../assets/payyment/copy.svg" alt="" />
                  </p>

                  <p class="title">ABA/ Routing Number：</p>
                  <p class="text">
                    026073150
                    <img @click="copyTextToClipboard" src="../../assets/payyment/copy.svg" alt="" />
                  </p>

                  <p class="title">{{ $fanyi('银行地址') }}</p>
                  <p class="text">
                    Seventh Avenue, New York, NY 10019, US
                    <img @click="copyTextToClipboard" src="../../assets/payyment/copy.svg" alt="" />
                  </p>

                  <p style="
font-family: PingFangSC, PingFang SC;
font-weight: 400;
font-size: 14px;
color: #999999;
line-height: 22px;">
                    Remark：<br />When you make an online bank Transfer, please include our full account name in the
                    remarks: Sameway Global Trade Investment Limited.
                  </p>
                </template>
              </div>
            </div>
            <!--表单信息  -->
            <div class="ruleform">
              <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
                <el-form-item class="date" :label="$fanyi('付款日期')" prop="pay_date">
                  <!-- <el-input v-model="ruleForm.pay_date" :placeholder="$fanyi('请输入付款日期')"></el-input> -->
                  <div>
                    <el-date-picker value-format="yyyy-MM-dd" v-model="ruleForm.pay_date" type="date"
                      :placeholder="$fanyi('请输入付款日期')">
                    </el-date-picker>
                  </div>
                </el-form-item>
                <el-form-item :label="$fanyi('提交人')" prop="pay_realname">
                  <el-input v-model="ruleForm.pay_realname" :placeholder="$fanyi('请输入提交人')"></el-input>
                </el-form-item>
                <el-form-item :label="$fanyi('付款金额')" prop="pay_amount">
                  <el-input v-model="ruleForm.pay_amount" :placeholder="$fanyi('请输入付款金额')"></el-input>
                </el-form-item>
                <el-form-item :label="$fanyi('备注')" prop="pay_remark">
                  <el-input v-model="ruleForm.pay_remark" :placeholder="$fanyi('请输入备注信息')"></el-input>
                </el-form-item>
                <el-form-item :label="$fanyi('付款截图')" prop="pay_image_url">
                  <el-upload class="avatar-uploader" action="https://laravel.rakumart.us/api/common/uploadFile"
                    :show-file-list="false" accept=".jpg,.jpeg,.png" :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload">
                    <img v-if="imageUrl" :src="ruleForm.pay_image_url" class="avatar" />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </el-form-item>
              </el-form>
            </div>
            <!-- 确认转账 -->
            <div class="Confirmthetransfer" @click="Confirmthetransfer">
              {{ $fanyi('确认转账') }}
            </div>
            <div class="bankPayAgreementContainer">
              <el-checkbox v-model="onlinePayAgreementChecked"></el-checkbox>
              <p @click="Userprotocol">
                {{ $fanyi('我知道并同意本网站的') }}
                <span>{{ $fanyi('隐私政策条款和条件') }}</span>
              </p>
            </div>
          </div>
          <!-- 在线支付 -->
          <div class="creditCardPay" v-if="payType == 1">
            <div class="onlinePayContainer">
              <div class="balancePayHeaderContainer" style="margin-top: 30px">
                <div class="flexAndCenter balancePayHeaderTopContainer">
                  <div>{{ $fanyi('订单金额') }}</div>
                  <div style="font-family: Roboto-Regular, Roboto; color: #ff730b">
                    ${{ $fun.EURNumSegmentation(payAll) }}
                  </div>
                </div>
                <div class="flexAndCenter balancePayHeaderBottomContainer">
                  <div>{{ $fanyi('订单应付款金额') }}</div>
                  <div style="
                                                                                                  font-size: 24px;
                                                                                                  font-family: Roboto-Bold, Roboto;
                                                                                                  font-weight: bold;
                                                                                                  color: #ff730b;
                                                                                                ">
                    ${{ $fun.EURNumSegmentation(payAllCNPrice) }}
                  </div>
                </div>
              </div>
              <div class="balancePayHeaderContainer" style="margin-top: 36px; border-bottom: 1px none">
                <div class="flexAndCenter balancePayHeaderTopContainer rujinjin">
                  <div>{{ $fanyi('最低入金金额') }}</div>
                  <div style="
                                                                                                  font-size: 24px;
                                                                                                  font-family: Roboto-Bold, Roboto;
                                                                                                  font-weight: bold;
                                                                                                  color: #222222;
                                                                                                ">
                    ${{
                      balance > 0 && balance - payAllCNPrice > 0
                        ? $fun.EURNumSegmentation(payAllCNPrice)
                        : datas.min_bank_pay_amount
                    }}
                  </div>
                </div>
                <div style="
                                                                                                width: 480px;
                                                                                                height: 1px;
                                                                                                border: 1px dashed #e2e2e2;
                                                                                                margin-top: 30px;
                                                                                              "></div>
                <div class="zhifufangshi" style="
                                                                                                display: flex;
                                                                                                justify-content: center;
                                                                                                align-items: center;
                                                                                                margin-top: 30px;
                                                                                              ">
                  <div class="pingpong">
                    <img src="../../assets/PingPong_logo.svg" alt="" />
                  </div>
                </div>
                <div class="payPriceContainer">
                  <div class="payment">
                    <span>*</span>{{ $fanyi('支付金额') }}
                  </div>
                  <el-input v-model="payInput" :disabled="true" type="number" :placeholder="$fanyi('请输入支付金额')" />
                </div>
              </div>
              <div class="balancePayBtn" @click="showBankPayDialog(2)">
                {{ $fanyi('确认付款') }}
              </div>
              <div class="bankPayAgreementContainer">
                <el-checkbox v-model="onlinePayAgreementChecked"></el-checkbox>
                <p @click="Userprotocol">
                  {{ $fanyi('我知道并同意本网站的') }}
                  <span>{{ $fanyi('隐私政策条款和条件') }}</span>
                </p>
              </div>
            </div>
          </div>

          <!-- payPal支付 -->
          <div class="creditCardPay" v-if="payType == 2">
            <div class="onlinePayContainer">
              <div class="balancePayHeaderContainer" style="margin-top: 30px">
                <div class="flexAndCenter balancePayHeaderTopContainer">
                  <div>{{ $fanyi('可用余额') }}</div>
                  <div style="font-family: Roboto-Regular, Roboto; color: #ff730b">
                    ${{ $fun.EURNumSegmentation(balance) }}
                  </div>
                </div>
                <div class="flexAndCenter balancePayHeaderTopContainer">
                  <div>{{ $fanyi('订单金额') }}</div>
                  <div style="font-family: Roboto-Regular, Roboto; color: #ff730b">
                    ${{ $fun.EURNumSegmentation(payAll) }}
                  </div>
                </div>
                <div class="flexAndCenter balancePayHeaderBottomContainer">
                  <div>{{ $fanyi('订单应付款金额') }}</div>
                  <div style="
                                                                                                  font-size: 24px;
                                                                                                  font-family: Roboto-Bold, Roboto;
                                                                                                  font-weight: bold;
                                                                                                  color: #ff730b;
                                                                                                ">
                    ${{ $fun.EURNumSegmentation(payAllCNPrice) }}
                  </div>
                </div>
              </div>
              <div class="balancePayHeaderContainer" style="margin-top: 36px; border-bottom: 1px none">
                <div class="flexAndCenter balancePayHeaderTopContainer rujinjin">
                  <div>{{ $fanyi('最低入金金额') }}</div>
                  <div style="
                                                                                                  font-size: 24px;
                                                                                                  font-family: Roboto-Bold, Roboto;
                                                                                                  font-weight: bold;
                                                                                                  color: #222222;
                                                                                                ">
                    ${{
                      balance > 0 && balance - payAllCNPrice > 0
                        ? $fun.EURNumSegmentation(payAllCNPrice)
                        : datas.min_bank_pay_amount
                    }}
                  </div>
                </div>
                <div style="
                                                                                                width: 480px;
                                                                                                height: 1px;
                                                                                                border: 1px dashed #e2e2e2;
                                                                                                margin-top: 30px;
                                                                                              "></div>
                <div class="zhifufangshi" style="
                                                                                                display: flex;
                                                                                                justify-content: center;
                                                                                                align-items: center;
                                                                                                margin-top: 30px;
                                                                                              ">
                  <div class="pingpong">
                    <img src="../../assets/pp_fc_hl.svg" alt="" />
                  </div>
                </div>
                <div class="paypal">
                  <a target="_blank"
                    href="https://www.paypal.com/us/cshelp/article/how-do-i-link-a-debit-or-credit-card-to-my-paypal-account-help155">
                    <img style="width: 16px;" src="../../assets/paypawhyl.svg" alt=""> How to link your credit card to
                    PayPal</a>
                </div>
                <div class="Paymentprompt">
                  <P>1.{{ $fanyi('请输入充值金额，使其超过估价总额') }}</P>
                  <P>2.{{
                    $fanyi('paypal付款的情况下，还需要4%的手续费，请事先谅解')
                  }}</P>
                </div>
                <div class="payPriceContainer">
                  <div class="payment">
                    <span>*</span>{{ $fanyi('支付金额') }}
                  </div>
                  <el-input v-model="payInput" :disabled="true" type="number" :placeholder="$fanyi('请输入支付金额')" />
                </div>
                <!-- <div class="Total" style=" margin-top: 10px; ">({{ $fanyi('总计') }}:&nbsp; {{ payInput < 1 ? ((1 * 0.045) +
                  payInput).toFixed(2) : ((payInput * 0.045) + payInput).toFixed(2) }} &nbsp;&nbsp; {{ $fanyi('手续费')
    + ' 4.5%' }} )</div> -->
              </div>
              <div class="balancePayBtn" @click="showBankPayDialog(2)">
                {{ $fanyi('确认付款') }}
              </div>
              <div class="bankPayAgreementContainer">
                <el-checkbox v-model="onlinePayAgreementChecked"></el-checkbox>
                <p @click="Userprotocol">
                  {{ $fanyi('我知道并同意本网站的') }}
                  <span>{{ $fanyi('隐私政策条款和条件') }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="payStep2 payPage" v-if="step == 2">
      <div class="payStep2Left">
        <div class="successMesBox">
          <div class="successMesBoxCon">
            <div class="succTitle">
              <img :src="require('../../assets/newImg/omplete.svg')" alt="" />

              <div class="over">{{ $fanyi('订单支付完成') }}</div>
            </div>
            <div class="succBody">
              {{ $fanyi('谢谢，你的订单号') }}{{ $route.query.order_sn }}
              {{
                $fanyi(
                  '已生成正确，并且已经在发送到我们仓库的过程中。 我们已经向您发送了一封包含详细信息的电子邮件。请按照个人中心中的状态进行操作'
                )
              }}
            </div>
            <button @click="$fun.toPage('/user/index')" class="successBtn">
              {{ $fanyi('控制面板') }}
            </button>
          </div>
        </div>
        <paySuccessInfoBlockVue :title="$fanyi('我的收货地址')">
          <div class="jinkoushang">
            <div class="jinkoushanginfo">
              <!-- <div class="flexAndCenter">
                <div class="jinkoushanginfoLabel">{{ $fanyi('公司') }}:</div>
                <div class="jinkoushanginfoValue">
                  {{ orderaddress.company }}
                </div>
              </div> -->
              <div class="flexAndCenter">
                <div class="jinkoushanginfoLabel">{{ $fanyi('地址') }}:</div>
                <div class="jinkoushanginfoValue">
                  {{ orderaddress.address }}
                </div>
              </div>
              <div class="flexAndCenter">
                <div class="jinkoushanginfoLabel">{{ $fanyi('邮编') }}:</div>
                <div class="jinkoushanginfoValue">
                  {{ orderaddress.zip_code }}
                </div>
              </div>
              <div class="flexAndCenter">
                <div class="jinkoushanginfoLabel">{{ $fanyi('城市') }}:</div>
                <div class="jinkoushanginfoValue">
                  {{ orderaddress.city }}
                </div>
              </div>
              <div class="flexAndCenter">
                <div class="jinkoushanginfoLabel">{{ $fanyi('州') }}:</div>
                <div class="jinkoushanginfoValue">
                  {{ $fanyi(orderaddress.state) }}
                </div>
              </div>
              <div class="flexAndCenter">
                <div class="jinkoushanginfoLabel">{{ $fanyi('手机号') }}:</div>
                <div class="jinkoushanginfoValue">
                  {{ orderaddress.mobile }}
                </div>
              </div>
              <div class="flexAndCenter">
                <div class="jinkoushanginfoLabel">
                  {{ $fanyi('电子邮箱') }}:
                </div>
                <div class="jinkoushanginfoValue">
                  {{ orderaddress.email }}
                </div>
              </div>
            </div>
            <changeInformationVue></changeInformationVue>
          </div>
        </paySuccessInfoBlockVue>
        <paySuccessInfoBlockVue :title="$fanyi('订单摘要')">
          <div class="goodsBox marginhave" :class="{ GoodsArrAll: showGoodsArrAll }">
            <div class="goodsArr">
              <div class="goodsHead goodsTr">
                <div class="goodsTd goodshe goodsName">
                  {{ $fanyi('产品') }}
                </div>
                <div class="goodsTd goodshe goodsNumber">
                  {{ $fanyi('购买数量') }}
                </div>
                <div class="goodsTd goodshe goodsPrice">
                  {{ $fanyi('全部费用') }}
                </div>
              </div>
              <div class="goodsOpt goodsTr" v-for="goodsItem in buyGoodsArr" :key="'good' + goodsItem.id">
                <div class="goodsTd goodsBo goodsName">
                  <el-popover placement="right" trigger="hover">
                    <img :src="goodsItem.pic" alt="" style="width: 300px; height: 300px" />

                    <el-image slot="reference" :src="goodsItem.pic">
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                      <div slot="placeholder">
                        <i class="el-icon-loading"></i>
                      </div>
                    </el-image>
                  </el-popover>
                  <el-popover placement="bottom" trigger="hover" width="800">
                    {{ goodsItem.goods_title }}
                    <span slot="reference" class="goodsTitle">
                      {{ goodsItem.goods_title }}
                    </span>
                  </el-popover>
                </div>
                <div class="goodsTd goodsBo goodsNumber">
                  {{ goodsItem.confirm_num }}
                </div>
                <div class="goodsTd goodsBo goodsPrice">
                  <p>
                    ${{
                      $fun.RMBNumSegmentation(
                        $fun.ceil(
                          goodsItem.confirm_num *
                          goodsItem.confirm_price *
                          goodsdatas.exchange_rate
                        )
                      )
                    }}
                  </p>
                  <p>
                    ({{
                      $fun.RMBNumSegmentation(
                        $fun.ceil(
                          goodsItem.china_amount.list.china_goods_amount
                        )
                      )
                    }}￥)
                  </p>
                </div>
              </div>
            </div>
            <div class="showAllRow">
              <img class="jiahao" src="../../assets/pulldown_1.svg" alt="" v-if="!showGoodsArrAll"
                @click="showGoodsArrAll = !showGoodsArrAll" />
              <img v-else class="jiahao" src="../../assets/pulldown_0.svg" alt=""
                @click="showGoodsArrAll = !showGoodsArrAll" />
            </div>
          </div>
        </paySuccessInfoBlockVue>
      </div>
      <div class="payStep2Right">
        <h3 class="nextTitle">
          {{ $fanyi('质量控制和处理') }}
        </h3>
        <div class="nextStepBox">
          <div class="nextStepOpt">
            <div class="nextStepNum">1</div>
            <div class="nextStepCon">
              <h4>{{ $fanyi('我们从工厂接收你们的产品') }}</h4>
              <p>
                {{ $fanyi('购买您的产品的过程已经开始。') }}
                <br />
                {{ $fanyi('我们期待在我们的仓库收到你们的产品。') }}
              </p>
            </div>
          </div>
          <div class="nextStepOpt">
            <div class="nextStepNum">2</div>
            <div class="nextStepCon">
              <h4>{{ $fanyi('我们从工厂接收你们的产品') }}</h4>
              <p v-html="$fanyi(
                '检品人员在收到货物后会进行货物的质量检测并完成您的附加服务（如果您的订单有选择附加服务）'
              )
                "></p>
            </div>
          </div>
          <div class="nextStepOpt">
            <div class="nextStepNum">3</div>
            <div class="nextStepCon">
              <h4>{{ $fanyi('运费和运输报价') }}</h4>
              <p>
                {{
                  $fanyi(
                    '我们将会选择最合理的国际运输方式，并为您报价，在5-7天内装运完成您的货物-7天内装运你的货物。'
                  )
                }}
              </p>
            </div>
          </div>
          <div class="nextStepOpt">
            <div class="nextStepNum">4</div>
            <div class="nextStepCon">
              <h4>{{ $fanyi('运费支付') }}</h4>
              <p>
                {{ $fanyi('付款后，您的货物将被存放在rakumart中国仓库。') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--? 订单付款余额,余额 -->
    <!-- 余额 -->
    <el-dialog :visible.sync="userBalancePayDialogVisible" width="400px" :show-close="false">
      <div class="flexAndCenter couponHeader">
        <div>{{ $fanyi('提醒') }}</div>
        <i class="el-icon-close" @click="userBalancePayDialogVisible = false"></i>
      </div>
      <div class="couponFooter">
        <div class="remindHeaderContainer flexAndCenter">
          <img src="../../assets/newRemind.png" alt="" />

          <div>
            {{ $fanyi('是否使用余额支付?') }}
          </div>
        </div>
        <div class="remindBtnList flexAndCenter">
          <div @click="submitUserBalancePayOrder">
            {{ $fanyi('确认') }}
          </div>

          <div @click="userBalancePayDialogVisible = false">
            {{ $fanyi('取消') }}
          </div>
        </div>
      </div>
    </el-dialog>
    <!--! 银行卡或者在线  点击在线支付弹窗,在线支付和银行卡共用 -->
    <el-dialog class="Rabobank" :visible.sync="onlineorderpayment" width="400px" :show-close="false">
      <div class="flexAndCenter couponHeader">
        <div>{{ $fanyi('提醒') }}</div>
        <i class="el-icon-close" @click="onlineorderpayment = false"></i>
      </div>
      <div class="couponFooter" style="margin: 0; padding-left: 20px">
        <div class="remindHeaderContainer">
          <!-- <img src="../../assets/newRemind.png" alt="" /> -->

          <div v-if="payType == 0">
            {{ $fanyi('是否确认提交汇款?') }}
          </div>
          <!-- <div v-if="payType == 1">
            {{ $fanyi('是否使用在线支付？') }}
          </div> -->
          <div v-if="payType == 2">
            {{ $fanyi('是否使用PayPal支付？') }}
          </div>
        </div>
        <div class="Natixis">
          <div @click="onlineorderpayment = false">
            {{ $fanyi('取消') }}
          </div>
          <!-- 银行卡 -->
          <div v-if="payType == 0" @click="orderdebitcard">
            {{ $fanyi('确认') }}
          </div>
          <!-- 在线支付 -->
          <!-- <div v-if="payType == 1" @click="Onlinepayment">
            {{ $fanyi('确认') }}
          </div> -->
          <!-- PayPal -->
          <div v-if="payType == 2" @click="Paypalpayment">
            {{ $fanyi('确认') }}
          </div>
        </div>
      </div>
    </el-dialog>
    <Dialog ref="dialogzhifu" :config="config1" />
  </div>

  <!-- 配送单支付页面 -->
  <div v-else v-loading.fullscreen.lock="payLoading" class="page">
    <Dialog ref="dialog" :config="config" />

    <HomePageTop />

    <stepShowVue></stepShowVue>
    <Paymentuseragreement :Userprotocol="protocol" @clonseUserprotocol="clonseUserprotocol"></Paymentuseragreement>
    <div class="payStep1 payPage" v-if="step == 1">
      <div class="payLeftCon">
        <div class="wuLiuZuiZhongBaoJia">
          <h1>{{ $fanyi('国际航运的最终报价') }}</h1>
          <h2>{{ $fanyi('进口总成本细目(包括物流和税收)') }}</h2>
          <div class="priceBox">
            <div class="priceOpt">
              <label>{{ $fanyi('国际运输') }}</label>
              <span class="PriceOuYuan">{{
                $fun.EURNumSegmentation(goodsdatas.logistics_price)
              }}
                $</span>
            </div>
            <div class="priceOpt">
              <label>{{ $fanyi('美国国内运输费用') }}</label>
              <span class="PriceOuYuan">{{
                $fun.EURNumSegmentation(goodsdatas.foreign_express_price)
              }}
                $</span>
            </div>
            <div class="priceOpt">
              <label>{{ $fanyi('其他费用') }}</label>
              <span class="PriceOuYuan">{{ $fun.EURNumSegmentation(goodsdatas.other_price) }} $</span>
            </div>
            <!--            <div class="priceOpt">-->
            <!--              <label>{{ $fanyi("反倾销税") }}</label>-->
            <!--              <span class="PriceOuYuan"-->
            <!--              >{{-->
            <!--                  $fun.EURNumSegmentation(goodsdatas.clearance_price)-->
            <!--                }}-->
            <!--                $</span-->
            <!--              >-->
            <!--            </div>-->
            <!--            <div class="priceOpt">-->
            <!--              <label>{{ $fanyi("增值税") }}</label>-->
            <!--              <span class="PriceOuYuan"-->
            <!--              >{{ $fun.EURNumSegmentation(goodsdatas.added_price) }} $</span-->
            <!--              >-->
            <!--            </div>-->
          </div>
          <div class="shouldPayPrice">
            <label>{{ $fanyi('应付总额') }}</label>
            <span>
              {{
                $fun.EURNumSegmentation(
                  isPeiSongDan
                    ? $fun.ceil(payAllCNPrice)
                    : $fun.ceil(payAllCNPrice * $store.state.exchangeRate)
                )
              }}
              $
            </span>
          </div>
        </div>
        <paySuccessInfoBlockVue :title="$fanyi('我的收货地址')">
          <div class="jinkoushang">
            <div class="jinkoushanginfo">
              <div class="flexAndCenter" style="margin-bottom: 8px">
                <div class="jinkoushanginfoLabel">{{ $fanyi('收件人') }}:</div>
                <div class="jinkoushanginfoValue">
                  {{ goodsdatas.orderAddress.consignee_address.contacts }}
                </div>
              </div>
              <div class="flexAndCenter" style="margin-bottom: 8px">
                <div class="jinkoushanginfoLabel">{{ $fanyi('地址') }}:</div>
                <div class="jinkoushanginfoValue">
                  {{ goodsdatas.orderAddress.consignee_address.address }}
                </div>
              </div>
              <div class="flexAndCenter" style="margin-bottom: 8px">
                <div class="jinkoushanginfoLabel">{{ $fanyi('邮编') }}:</div>
                <div class="jinkoushanginfoValue">
                  {{ goodsdatas.orderAddress.consignee_address.zip_code }}
                </div>
              </div>
              <div class="flexAndCenter" style="margin-bottom: 8px">
                <div class="jinkoushanginfoLabel">{{ $fanyi('城市') }}:</div>
                <div class="jinkoushanginfoValue">
                  {{ goodsdatas.orderAddress.consignee_address.city }}
                </div>
              </div>
              <div class="flexAndCenter" style="margin-bottom: 8px">
                <div class="jinkoushanginfoLabel">{{ $fanyi('州') }}:</div>
                <div class="jinkoushanginfoValue">
                  {{ $fanyi(goodsdatas.orderAddress.consignee_address.state) }}
                </div>
              </div>
              <div class="flexAndCenter" style="margin-bottom: 8px">
                <div class="jinkoushanginfoLabel">{{ $fanyi('手机号') }}:</div>
                <div class="jinkoushanginfoValue">
                  {{ goodsdatas.orderAddress.consignee_address.mobile }}
                </div>
              </div>
              <div class="flexAndCenter">
                <div class="jinkoushanginfoLabel">
                  {{ $fanyi('电子邮箱') }}:
                </div>
                <div class="jinkoushanginfoValue">
                  {{ goodsdatas.orderAddress.consignee_address.email }}
                </div>
              </div>
            </div>
            <changeInformationVue></changeInformationVue>
          </div>
        </paySuccessInfoBlockVue>
        <!-- 订单摘要 -->
        <paySuccessInfoBlockVue :title="$fanyi('订单摘要')">
          <div class="goodsBox marginhave" :class="{ GoodsArrAll: showGoodsArrAll }">
            <div class="goodsArr">
              <div class="goodsHead goodsTr">
                <div class="goodsTd goodshe goodsName" style="margin-left: 20px">
                  {{ $fanyi('产品') }}
                </div>
                <div class="goodsTd goodshe goodsNumber">
                  {{ $fanyi('购买数量') }}
                </div>
                <div class="goodsTd goodshe goodsPrice">
                  {{ $fanyi('全部费用') }}
                </div>
              </div>
              <div class="goodsOpt goodsTr" v-for="goodsItem in buyGoodsArr" :key="'good' + goodsItem.id">
                <div class="goodsTd goodsBo goodsName">
                  <el-popover placement="right" trigger="hover">
                    <img :src="goodsItem.pic" alt="" style="width: 300px; height: 300px" />

                    <el-image slot="reference" :src="goodsItem.pic">
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                      <div slot="placeholder">
                        <i class="el-icon-loading"></i>
                      </div>
                    </el-image>
                  </el-popover>
                  <el-popover placement="bottom" trigger="hover" width="800">
                    {{ goodsItem.goods_title }}
                    <span slot="reference" class="goodsTitle">
                      {{ goodsItem.goods_title }}
                    </span>
                  </el-popover>
                </div>
                <div class="goodsTd goodsBo goodsNumber">
                  {{ goodsItem.submitNum }}
                </div>
                <div class="goodsTd goodsBo goodsPrice">
                  <p>
                    ${{
                      $fun.RMBNumSegmentation(
                        $fun.ceil(
                          goodsItem.confirm_num *
                          goodsItem.confirm_price *
                          goodsdatas.exchange_rate
                        )
                      )
                    }}
                  </p>
                  <p>
                    ({{
                      $fun.RMBNumSegmentation(
                        $fun.ceil(goodsItem.num * goodsItem.confirm_price)
                      )
                    }}￥)
                  </p>
                </div>
              </div>
            </div>
            <div class="showAllRow">
              <img class="jiahao" src="../../assets/pulldown_1.svg" alt="" v-if="!showGoodsArrAll"
                @click="showGoodsArrAll = !showGoodsArrAll" />
              <img v-else class="jiahao" src="../../assets/pulldown_0.svg" alt=""
                @click="showGoodsArrAll = !showGoodsArrAll" />
            </div>
          </div>
        </paySuccessInfoBlockVue>
      </div>

      <div class="payRightCon">
        <!--! 余额支付 余额大于0 并且余额减去订单金额大于等于零显示余额支付 -->
        <!-- "balance > 0 && balance - payAllCNPrice >= 0" -->
        <div class="balance" v-if="balance > 0 && balance - payAllCNPrice >= 0">
          <div class="top-title">
            {{ $fanyi('余额支付') }}
            <span></span>
          </div>
          <!-- 订单金额 -->
          <div class="oeder-box" style="border-bottom: 1px dashed #e2e2e2">
            <div class="order">
              <span>{{ $fanyi('订单金额') }}</span>
              <span>${{ $fun.EURNumSegmentation(payAll) }}</span>
            </div>
            <div class="jine">
              <span>{{ $fanyi('应付款金额') }}</span>
              <span> $ {{ $fun.EURNumSegmentation(payAllCNPrice) }}</span>
            </div>
          </div>
          <div class="oeder-box" style="border-bottom: 1px solid #222222">
            <div class="useblane">
              <span>{{ $fanyi('账户余额') }}</span>
              <span>$ {{ $fun.EURNumSegmentation(balance) }}</span>
            </div>
            <div class="paymentblane">
              <span>{{ $fanyi('付款后余额') }}</span>
              <span>
                $
                {{
                  $fun.EURNumSegmentation((balance - payAllCNPrice).toFixed(2))
                }}</span>
            </div>
          </div>
          <div class="balancePayBtn" @click="blanepayment">
            {{ $fanyi('支付') }}
          </div>
          <div class="bankPayAgreementContainer">
            <el-checkbox v-model="balancePayAgreementChecked"></el-checkbox>
            <p @click="Userprotocol">
              {{ $fanyi('我知道并同意本网站的') }}
              <span>{{ $fanyi('隐私政策条款和条件') }}</span>
            </p>
          </div>
        </div>

        <!-- ? 银行卡 支付 balance < 0 || balance - payAllCNPrice < 0-->
        <div class="debitcard" v-if="balance < 0 || balance - payAllCNPrice < 0">
          <div class="payTypeChiose">
            <div class="allPayType">
              <div @click="payType = 2" :class="{ active: payType == 2 }">
                <img style="margin-bottom: 6px;" src="../../assets/payyment/paypal.svg" alt="" />
              </div>
              <!-- @click="payType = 0" -->
              <div :class="{ active: payType == 0 }">
                <el-popover ref="bankTipRef" placement="bottom" width="225" trigger="hover">
                  <div class="Bankingmode">
                    <p @click="closePopover('ACH')">ACH</p>
                    <p @click="closePopover('Fedwire')">Fedwire</p>
                  </div>
                  <p slot="reference">
                    <img src="../../assets/payyment/xt.svg" style="margin-right: 5px;" alt="" />
                    {{ bank }}
                    <span style="
                    font-size: 16px;font-weight: 600 " v-if="Bankingmode">{{ '(' + Bankingmode + ')' }}</span>
                  </p>
                </el-popover>
              </div>
              <!-- <div @click="payType = 1" :class="{ active: payType == 1 }">
                {{ $fanyi('PingPong') }}
              </div> -->
            </div>
            <div class="chioseBlock" :class="{
              xinyongka: payType == 1,
              paypal: payType == 2,
              zaixianzhufu: payType == 0,
            }"></div>
          </div>

          <!-- 银行卡支付 payType == 0-->
          <div v-if="payType == 0" class="Rabobank">
            <div class="top-box">
              <div class="useblane">
                <span>{{ $fanyi('账户余额') }}</span>
                <span>$ {{ $fun.EURNumSegmentation(balance) }}</span>
              </div>
              <div class="Lowest">
                <span>{{ $fanyi('最低入金金额') }}</span><span>
                  ${{
                    balance > 0 && balance - payAllCNPrice > 0
                      ? $fun.EURNumSegmentation(payAllCNPrice)
                      : datas.min_bank_pay_amount
                  }}</span>
              </div>
            </div>
            <!-- 银行卡信息区域 -->
            <div class="content">
              <div class="pic-box">
                <img v-if="Bankingmode == 'Fedwire'" src="../../assets/payyment/JPMorgan-logo.png" alt="" />
                <img v-if="Bankingmode == 'ACH'" src="../../assets/payyment/cfsb.png" alt="" />
              </div>
              <!-- 收件人银行信息 -->
              <!-- 弹窗如何运作的 -->
              <el-dialog :title="$fanyi('它是如何运作的?')" :visible.sync="showOperate" width="30%">
                <div class="operationshow">
                  <div class="left">
                    <div class="one">1</div>
                    <span></span>
                    <div class="two">2</div>
                  </div>
                  <div class="right">
                    <div class="box">
                      <h3>{{ $fanyi('银行转账') }}</h3>
                      <p>{{ $fanyi('将您订单中需花费的钱转账到我们账户') }}</p>
                    </div>
                    <div class="box" style="margin: 0px">
                      <h3>{{ $fanyi('等待处理') }}</h3>
                      <p>{{ $fanyi('等待1至2个工作日处理订单.') }}</p>
                    </div>
                  </div>
                </div>
                <span slot="footer" class="showOperate-footer">
                  <el-button type="primary" class="queren" @click="showOperate = false">{{ $fanyi('确认') }}</el-button>
                </span>
              </el-dialog>
              <div class="Attninfo">
                <h2>{{ $fanyi('收件人银行信息') }}</h2>
                <p>
                  {{
                    $fanyi(
                      '使用以下银行数据通过您的在线银行或移动应用程序进行转账，订单处理后，将进行购买。'
                    )
                  }}
                </p>
                <div class="operation" @click="showOperate = true">
                  <img src="../../assets/icon/why.svg" alt="" />
                  <span>{{ $fanyi('它是如何运作的?') }}</span>
                </div>
                <h2 style="margin-bottom: 20px">
                  {{ $fanyi('使用订单号作为付款概念/参考') }}
                </h2>
                <div class="ordersn">
                  <img src="../../assets/order.svg" alt="" />
                  <span>
                    {{
                      $route.query.order_sn
                        ? $route.query.order_sn
                        : $route.query.porder_sn
                    }}</span>
                </div>

                <template v-if="Bankingmode == 'Fedwire'">
                  <p class="title">{{ $fanyi('账号名称') }}</p>
                  <p class="text">
                    Sameway Global Trade Investment Limited
                    <img @click="Copytheaccountname($route.query.porder_sn)" src="../../assets/payyment/copy.svg"
                      alt="" />
                  </p>

                  <p class="title">{{ $fanyi('银行账户') }}</p>
                  <p class="text">
                    20000011884118
                    <img @click="copyTextToClipboard" src="../../assets/payyment/copy.svg" alt="" />
                  </p>

                  <p class="title">{{ $fanyi('银行名称') }}</p>
                  <p class="text">
                    JPMORGAN CHASE BANK, N.A
                    <img @click="copyTextToClipboard" src="../../assets/payyment/copy.svg" alt="" />
                  </p>

                  <p class="title">ABA/ Routing Number：</p>
                  <p class="text">
                    021000021
                    <img @click="copyTextToClipboard" src="../../assets/payyment/copy.svg" alt="" />
                  </p>

                  <p class="title">{{ $fanyi('银行地址') }}</p>
                  <p class="text">
                    JPMORGAN CHASE BANK, N.A. COLUMBUS,OH 43240 COLUMBUS,OH US
                    UNITED STATES OF AMERICA
                    <img @click="copyTextToClipboard" src="../../assets/payyment/copy.svg" alt="" />
                  </p>
                  <p style="
                  font-family: PingFangSC, PingFang SC;
font-weight: 400;
font-size: 14px;
color: #999999;
line-height: 22px;">
                    Remark：<br />When you make an online bank Transfer, please include our full account name in the
                    remarks: Sameway Global Trade Investment Limited.
                  </p>
                </template>
                <template v-if="Bankingmode == 'ACH'">
                  <p class="title">{{ $fanyi('账号名称') }}</p>
                  <p class="text">
                    Sameway Global Trade Investment Limited
                    <img @click="Copytheaccountname($route.query.porder_sn)" src="../../assets/payyment/copy.svg"
                      alt="" />
                  </p>

                  <p class="title">{{ $fanyi('银行账户') }}</p>
                  <p class="text">
                    8338401008
                    <img @click="copyTextToClipboard" src="../../assets/payyment/copy.svg" alt="" />
                  </p>

                  <p class="title">{{ $fanyi('银行名称') }}</p>
                  <p class="text">
                    Community Federal Savings Bank
                    <img @click="copyTextToClipboard" src="../../assets/payyment/copy.svg" alt="" />
                  </p>

                  <p class="title">ABA/ Routing Number：</p>
                  <p class="text">
                    026073150
                    <img @click="copyTextToClipboard" src="../../assets/payyment/copy.svg" alt="" />
                  </p>

                  <p class="title">{{ $fanyi('银行地址') }}</p>
                  <p class="text">
                    Seventh Avenue, New York, NY 10019, US
                    <img @click="copyTextToClipboard" src="../../assets/payyment/copy.svg" alt="" />
                  </p>

                  <p style="
font-family: PingFangSC, PingFang SC;
font-weight: 400;
font-size: 14px;
color: #999999;
line-height: 22px;">
                    Remark：<br />When you make an online bank Transfer, please include our full account name in the
                    remarks: Sameway Global Trade Investment Limited.
                  </p>
                </template>
              </div>
            </div>
            <!--表单信息  -->
            <div class="ruleform">
              <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
                <el-form-item class="date" :label="$fanyi('付款日期')" prop="pay_date">
                  <div>
                    <el-date-picker value-format="yyyy-MM-dd" v-model="ruleForm.pay_date" type="date"
                      :placeholder="$fanyi('请输入付款日期')">
                    </el-date-picker>
                  </div>
                </el-form-item>
                <el-form-item :label="$fanyi('提交人')" prop="pay_realname">
                  <el-input v-model="ruleForm.pay_realname" :placeholder="$fanyi('请输入提交人')"></el-input>
                </el-form-item>
                <el-form-item :label="$fanyi('付款金额')" prop="pay_amount">
                  <el-input v-model="ruleForm.pay_amount" :placeholder="$fanyi('请输入付款金额')"></el-input>
                </el-form-item>
                <el-form-item :label="$fanyi('备注')" prop="pay_remark">
                  <el-input v-model="ruleForm.pay_remark" :placeholder="$fanyi('请输入备注信息')"></el-input>
                </el-form-item>
                <el-form-item :label="$fanyi('付款截图')" prop="pay_image_url">
                  <el-upload class="avatar-uploader" action="https://laravel.rakumart.us/api/common/uploadFile"
                    :show-file-list="false" accept=".jpg,.jpeg,.png" :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload">
                    <img v-if="imageUrl" :src="ruleForm.pay_image_url" class="avatar" />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </el-form-item>
              </el-form>
            </div>
            <!-- 确认转账 -->
            <div class="Confirmthetransfer" @click="Confirmthetransfer">
              {{ $fanyi('确认转账') }}
            </div>
            <div class="bankPayAgreementContainer">
              <el-checkbox v-model="onlinePayAgreementChecked"></el-checkbox>
              <p @click="Userprotocol">
                {{ $fanyi('我知道并同意本网站的') }}
                <span>{{ $fanyi('隐私政策条款和条件') }}</span>
              </p>
            </div>
          </div>
          <!-- 在线支付 -->
          <div class="creditCardPay" v-if="payType == 1">
            <div class="onlinePayContainer">
              <div class="balancePayHeaderContainer" style="margin-top: 30px">
                <div class="flexAndCenter balancePayHeaderTopContainer">
                  <div>{{ $fanyi('订单金额') }}</div>
                  <div style="font-family: Roboto-Regular, Roboto; color: #ff730b">
                    ${{ $fun.EURNumSegmentation(payAll) }}
                  </div>
                </div>
                <div class="flexAndCenter balancePayHeaderBottomContainer">
                  <div>{{ $fanyi('订单应付款金额') }}</div>
                  <div style="
                                                                                                  font-size: 24px;
                                                                                                  font-family: Roboto-Bold, Roboto;
                                                                                                  font-weight: bold;
                                                                                                  color: #ff730b;
                                                                                                ">
                    ${{ $fun.EURNumSegmentation(payAllCNPrice) }}
                  </div>
                </div>
              </div>
              <div class="balancePayHeaderContainer" style="margin-top: 36px; border-bottom: 1px none">
                <div class="flexAndCenter balancePayHeaderTopContainer rujinjin">
                  <div>{{ $fanyi('最低入金金额') }}</div>
                  <div style="
                                                                                                  font-size: 24px;
                                                                                                  font-family: Roboto-Bold, Roboto;
                                                                                                  font-weight: bold;
                                                                                                  color: #222222;
                                                                                                ">
                    ${{
                      balance > 0 && balance - payAllCNPrice > 0
                        ? $fun.EURNumSegmentation(payAllCNPrice)
                        : datas.min_bank_pay_amount
                    }}
                  </div>
                </div>
                <div style="
                                                                                                width: 480px;
                                                                                                height: 1px;
                                                                                                border: 1px dashed #e2e2e2;
                                                                                                margin-top: 30px;
                                                                                              "></div>
                <div class="zhifufangshi" style="
                                                                                                display: flex;
                                                                                                justify-content: center;
                                                                                                align-items: center;
                                                                                                margin-top: 30px;
                                                                                              ">
                  <div class="pingpong">
                    <img src="../../assets/PingPong_logo.svg" alt="" />
                  </div>
                </div>
                <div class="payPriceContainer">
                  <div class="payment">
                    <span>*</span>{{ $fanyi('支付金额') }}
                  </div>
                  <el-input v-model="porderpayInput" :disabled="true" type="number" :placeholder="$fanyi('请输入支付金额')" />
                </div>
              </div>
              <div class="balancePayBtn" @click="showBankPayDialog()">
                {{ $fanyi('确认付款') }}
              </div>
              <div class="bankPayAgreementContainer">
                <el-checkbox v-model="onlinePayAgreementChecked"></el-checkbox>
                <p @click="Userprotocol">
                  {{ $fanyi('我知道并同意本网站的') }}
                  <span>{{ $fanyi('隐私政策条款和条件') }}</span>
                </p>
              </div>
            </div>
          </div>

          <!-- payPal支付 -->
          <div class="creditCardPay" v-if="payType == 2">
            <div class="onlinePayContainer">
              <div class="balancePayHeaderContainer" style="margin-top: 30px">
                <div class="flexAndCenter balancePayHeaderTopContainer">
                  <div>{{ $fanyi('可用余额') }}</div>
                  <div style="font-family: Roboto-Regular, Roboto; color: #ff730b">
                    ${{ $fun.EURNumSegmentation(balance) }}
                  </div>
                </div>
                <div class="flexAndCenter balancePayHeaderTopContainer">
                  <div>{{ $fanyi('订单金额') }}</div>
                  <div style="font-family: Roboto-Regular, Roboto; color: #ff730b">
                    ${{ $fun.EURNumSegmentation(payAll) }}
                  </div>
                </div>
                <div class="flexAndCenter balancePayHeaderBottomContainer">
                  <div>{{ $fanyi('订单应付款金额') }}</div>
                  <div style="
                                                                                                  font-size: 24px;
                                                                                                  font-family: Roboto-Bold, Roboto;
                                                                                                  font-weight: bold;
                                                                                                  color: #ff730b;
                                                                                                ">
                    ${{ $fun.EURNumSegmentation(payAllCNPrice) }}
                  </div>
                </div>
              </div>
              <div class="balancePayHeaderContainer" style="margin-top: 36px; border-bottom: 1px none">
                <div class="flexAndCenter balancePayHeaderTopContainer rujinjin">
                  <div>{{ $fanyi('最低入金金额') }}</div>
                  <div style="
                                                                                                  font-size: 24px;
                                                                                                  font-family: Roboto-Bold, Roboto;
                                                                                                  font-weight: bold;
                                                                                                  color: #222222;
                                                                                                ">
                    ${{
                      balance > 0 && balance - payAllCNPrice > 0
                        ? $fun.EURNumSegmentation(payAllCNPrice)
                        : datas.min_bank_pay_amount
                    }}
                  </div>
                </div>
                <div style="
                                                                                                width: 480px;
                                                                                                height: 1px;
                                                                                                border: 1px dashed #e2e2e2;
                                                                                                margin-top: 30px;
                                                                                              "></div>
                <div class="zhifufangshi" style="
                                                                                                display: flex;
                                                                                                justify-content: center;
                                                                                                align-items: center;
                                                                                                margin-top: 30px;
                                                                                              ">
                  <div class="pingpong">
                    <img src="../../assets/pp_fc_hl.svg" alt="" />
                  </div>
                </div>

                <div class="Paymentprompt">
                  <P>1.{{ $fanyi('请输入充值金额，使其超过估价总额') }}</P>
                  <P>2.{{
                    $fanyi('paypal付款的情况下，还需要4%的手续费，请事先谅解')
                  }}</P>
                </div>
                <div class="payPriceContainer">
                  <div class="payment">
                    <span>*</span>{{ $fanyi('支付金额') }}
                  </div>
                  <el-input v-model="porderpayInput" :disabled="true" type="number" :placeholder="$fanyi('请输入支付金额')" />
                </div>
                <!-- <div class="Total" style=" margin-top: 10px; ">({{ $fanyi('总计') }}:&nbsp;{{ porderpayInput < 0 ? ((1 *
                  0.045) + porderpayInput).toFixed(2) : ((porderpayInput * 0.045) + porderpayInput).toFixed(2) }}
                    &nbsp;&nbsp; {{ $fanyi('手续费') + ' 4.5%' }} )</div> -->
              </div>
              <div class="balancePayBtn" @click="showBankPayDialog(2)">
                {{ $fanyi('确认付款') }}
              </div>
              <div class="bankPayAgreementContainer">
                <el-checkbox v-model="onlinePayAgreementChecked"></el-checkbox>
                <p @click="Userprotocol">
                  {{ $fanyi('我知道并同意本网站的') }}
                  <span>{{ $fanyi('隐私政策条款和条件') }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <!--? 订单付款余额,余额 -->
        <!-- 余额 -->
        <el-dialog :visible.sync="userBalancePayDialogVisible" width="400px" :show-close="false">
          <div class="flexAndCenter couponHeader">
            <div>{{ $fanyi('提醒') }}</div>
            <i class="el-icon-close" @click="userBalancePayDialogVisible = false"></i>
          </div>
          <div class="couponFooter">
            <div class="remindHeaderContainer flexAndCenter">
              <img src="../../assets/newRemind.png" alt="" />

              <div>
                {{ $fanyi('是否使用余额支付?') }}
              </div>
            </div>
            <!-- 配送单支付 -->
            <div class="remindBtnList flexAndCenter">
              <div @click="Deliveryorderpayment">
                {{ $fanyi('确认') }}
              </div>

              <div @click="userBalancePayDialogVisible = false">
                {{ $fanyi('取消') }}
              </div>
            </div>
          </div>
        </el-dialog>
        <!--! 银行卡或者在线  点击在线支付弹窗,在线支付和银行卡共用 -->
        <el-dialog class="Rabobank" :visible.sync="onlineorderpayment" width="400px" :show-close="false">
          <div class="flexAndCenter couponHeader">
            <div>{{ $fanyi('提醒') }}</div>
            <i class="el-icon-close" @click="onlineorderpayment = false"></i>
          </div>
          <div class="couponFooter" style="margin: 0; padding-left: 20px">
            <div class="remindHeaderContainer">
              <!-- <img src="../../assets/newRemind.png" alt="" /> -->

              <div v-if="payType == 0">
                {{ $fanyi('是否确认提交汇款?') }}
              </div>
              <!-- <div v-if="payType == 1">
                {{ $fanyi('是否使用在线支付？') }}
              </div> -->
              <div v-if="payType == 2">
                {{ $fanyi('是否使用PayPal支付？') }}
              </div>
            </div>
            <div class="Natixis">
              <div @click="onlineorderpayment = false">
                {{ $fanyi('取消') }}
              </div>
              <!-- 银行卡 -->
              <div v-if="payType == 0" @click="deliverdebitcard()">
                {{ $fanyi('确认') }}
              </div>
              <!-- 配送单在线支付 -->
              <!-- <div v-if="payType == 1" @click="Deliveryorderonlinepayment">
                {{ $fanyi('确认') }}
              </div> -->
              <!-- PayPal -->
              <div v-if="payType == 2" @click="Paypalpayment">
                {{ $fanyi('确认') }}
              </div>
            </div>
          </div>
        </el-dialog>
      </div>
    </div>
    <div class="payStep2 payPage" v-if="step == 2">
      <div class="payStep2Left ispeison">
        <!-- 成功消息框 -->
        <div class="successMesBox">
          <div class="successMesBoxCon">
            <div class="succTitle">
              <img :src="require('../../assets/newImg/omplete.svg')" alt="" />
              <div class="over">{{ $fanyi('订单支付完成') }}</div>
            </div>
            <div class="succBody">
              {{ $fanyi('谢谢，关于您的配送单号') }}
              {{ $route.query.porder_sn }}
              {{
                $fanyi(
                  '的发送：您已正确付款并且您的货物已在发送至我们的仓库。 商品到货时将提供国家快递（GLS）的追踪号码。您可以在个人中心中查看状态。'
                )
              }}
            </div>
            <button @click="$fun.toPage('/user/index')" class="successBtn">
              {{ $fanyi('控制面板') }}
            </button>
          </div>
        </div>

        <paySuccessInfoBlockVue :title="$fanyi('我的收货地址')">
          <div class="jinkoushang">
            <div class="jinkoushanginfo">
              <div class="flexAndCenter">
                <div class="jinkoushanginfoLabel">{{ $fanyi('收件人') }}:</div>
                <div class="jinkoushanginfoValue">
                  {{ goodsdatas.orderAddress.consignee_address.contacts }}
                </div>
              </div>
              <div class="flexAndCenter" style="margin-bottom: 8px">
                <div class="jinkoushanginfoLabel">{{ $fanyi('地址') }}:</div>
                <div class="jinkoushanginfoValue">
                  {{ goodsdatas.orderAddress.consignee_address.address }}
                </div>
              </div>
              <div class="flexAndCenter" style="margin-bottom: 8px">
                <div class="jinkoushanginfoLabel">{{ $fanyi('邮编') }}:</div>
                <div class="jinkoushanginfoValue">
                  {{ goodsdatas.orderAddress.consignee_address.zip_code }}
                </div>
              </div>
              <div class="flexAndCenter" style="margin-bottom: 8px">
                <div class="jinkoushanginfoLabel">{{ $fanyi('城市') }}:</div>
                <div class="jinkoushanginfoValue">
                  {{ goodsdatas.orderAddress.consignee_address.city }}
                </div>
              </div>
              <div class="flexAndCenter" style="margin-bottom: 8px">
                <div class="jinkoushanginfoLabel">{{ $fanyi('州') }}:</div>
                <div class="jinkoushanginfoValue">
                  {{ $fanyi(goodsdatas.orderAddress.consignee_address.state) }}
                </div>
              </div>
              <div class="flexAndCenter" style="margin-bottom: 8px">
                <div class="jinkoushanginfoLabel">{{ $fanyi('手机号') }}:</div>
                <div class="jinkoushanginfoValue">
                  {{ goodsdatas.orderAddress.consignee_address.mobile }}
                </div>
              </div>
              <div class="flexAndCenter">
                <div class="jinkoushanginfoLabel">
                  {{ $fanyi('电子邮箱') }}:
                </div>
                <div class="jinkoushanginfoValue">
                  {{ goodsdatas.orderAddress.consignee_address.email }}
                </div>
              </div>
            </div>
            <!-- <changeInformationVue> </changeInformationVue> -->
          </div>
        </paySuccessInfoBlockVue>
        <paySuccessInfoBlockVue :title="$route.query.porder_sn">
          <div class="goodsBox marginhave" :class="{ GoodsArrAll: showGoodsArrAll }">
            <div class="goodsArr">
              <div class="goodsHead goodsTr">
                <div class="goodsTd goodshe goodsName">
                  {{ $fanyi('产品') }}
                </div>
                <div class="goodsTd goodshe goodsNumber">
                  {{ $fanyi('购买数量') }}
                </div>
                <div class="goodsTd goodshe goodsPrice" style="margin">
                  {{ $fanyi('全部费用') }}
                </div>
              </div>
              <div class="goodsOpt goodsTr" v-for="goodsItem in buyGoodsArr" :key="'good' + goodsItem.id">
                <div class="goodsTd goodsBo goodsName">
                  <el-popover placement="right" trigger="hover">
                    <img :src="goodsItem.pic" alt="" style="width: 300px; height: 300px" />

                    <el-image slot="reference" :src="goodsItem.pic">
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                      <div slot="placeholder">
                        <i class="el-icon-loading"></i>
                      </div>
                    </el-image>
                  </el-popover>
                  <el-popover placement="bottom" trigger="hover" width="800">
                    {{ goodsItem.goods_title }}
                    <span slot="reference" class="goodsTitle">
                      {{ goodsItem.goods_title }}
                    </span>
                  </el-popover>
                </div>
                <div class="goodsTd goodsBo goodsNumber">
                  {{ goodsItem.confirm_num }}
                </div>
                <div class="goodsTd goodsBo goodsPrice">
                  <p>
                    ${{
                      $fun.RMBNumSegmentation(
                        $fun.ceil(
                          goodsItem.confirm_num *
                          goodsItem.confirm_price *
                          goodsdatas.exchange_rate
                        )
                      )
                    }}
                  </p>
                  <p>
                    ({{
                      $fun.RMBNumSegmentation(
                        $fun.ceil(goodsItem.num * goodsItem.confirm_price)
                      )
                    }}￥)
                  </p>
                </div>
              </div>
            </div>
            <div class="showAllRow">
              <img class="jiahao" src="../../assets/pulldown_1.svg" alt="" v-if="!showGoodsArrAll"
                @click="showGoodsArrAll = !showGoodsArrAll" />
              <img v-else class="jiahao" src="../../assets/pulldown_0.svg" alt=""
                @click="showGoodsArrAll = !showGoodsArrAll" />
            </div>
          </div>
        </paySuccessInfoBlockVue>
      </div>
    </div>
  </div>
</template>
<script>
const { Base64 } = require('js-base64')
import Dialog from '../../components/public/Dialog.vue'
import { getCurrentDay } from '../../utlis/date'
import HomePageTop from '../../components/head/HomePageTop.vue'

import stepShowVue from './components/stepShow.vue'
import paySuccessInfoBlockVue from './components/paySuccessInfoBlock.vue'
import changeInformationVue from './components/changeInformation.vue'
import dizhiForm from './components/dizhiForm.vue'
import jinkoushangForm from './components/jinkoushangForm.vue'
import Paymentuseragreement from './components/Paymentuseragreement'

export default {
  name: 'Payment',
  data() {
    // 校验文本是否全由空格组成
    var verificationText = (rule, value, callback) => {
      let text = value.trim()
      if (text === '') {
        callback(new Error(this.$fanyi('不能全由空格组成')))
      } else {
        callback()
      }
    }
    //价格校验
    var checkamount = (rule, value, callback) => {
      var price =
        this.balance > 0 && this.balance - this.payAllCNPrice > 0
          ? this.$fun.EURNumSegmentation(this.payAllCNPrice)
          : this.datas.min_bank_pay_amount

      if (value < price) {
        callback(new Error(this.$fanyi('付款金额不能小于最低入金金额')))
        ruleForm.pay_amount = price
      } else {
        callback()
      }
    }
    return {
      showGoodsArrAll: false, //显示信息是否展开
      balancePayAgreementChecked: true, // 余额协议
      payAll: '',
      flag: false,
      imageUrl: '',
      onlineorderpayment: false, //银行卡和在线弹窗
      onlinePayAgreementChecked: true, // 在线支付协议
      balance: '',
      showOperate: false,
      userAddress: this.$fanyi('地址'),
      bank: '',
      Bankingmode: '', // 银行模式
      goodsdatas: {
        logistics_price: 0,
        foreign_express_price: 0,
        clearance_price: 0,
        added_price: 0,

        orderAddress: {
          consignee_address: {
            type: 1, //1=收货地址,2=联系人
            company: '1', //公司名
            zip_code: '', //邮编
            address: '', //地址
            contacts: '', //	联系人
            mobile: '', //手机号
            tel: '1', //固定电话
            email: '', //邮箱
            cnpj: '1', //CNPJ(税号/身份证)
            mark: '1', //唛头
            city: '', //城市名
            country: '', //国家名
          },
          importer_address: {
            type: 2,
            company: '', //公司名
            zip_code: '', //邮编
            address: '', //地址
            contacts: '', //	联系人
            mobile: '', //手机号
            tel: '1', //固定电话
            email: '', //邮箱
            cnpj: '', //CNPJ(税号/身份证)
            mark: '1', //唛头
            city: '', //城市名
            country: '', //国家名
          },
        },
      }, //订单信息
      buyGoodsArr: [], //商品信息显示
      timer: 0,
      payType: 2, //付款方式 0-银行卡，1-信用卡
      checked: false, //是否同意条款
      step: 1, //支付步骤
      cardPayData: {
        Ds_MerchantParameters: '',
        Ds_Signature: '',
        Ds_SignatureVersion: '',
      },
      selectBankIndex: 0,
      payInput: '', //订单
      porderpayInput: '', //配送单
      iconList: [
        {
          img: require('../../assets/icon/caixiaBank.png'),
        },
        {
          img: require('../../assets/icon/redses.png'),
        },
      ],
      userBalancePayDialogVisible: false,
      payAllCNPrice: 0, //支付总价人民币

      payLoading: false, //支付加载中动画控制
      isPeiSongDan: false, //是否是配送单
      addId: {
        shouhuodizhi: '',
        jinkoushang: '',
      },
      shouhuodizhi: {
        type: 1,
        company: '', //公司名
        zip_code: '', //邮编
        address: '', //地址
        contacts: '', //	联系人
        mobile: '', //手机号
        tel: '', //固定电话
        email: '', //邮箱
        cnpj: '', //CNPJ(税号/身份证)
        mark: '', //唛头
        city: '', //城市名
        country: '', //国家名
      },
      jinkoushang: {
        type: 2,
        company: '', //公司名
        zip_code: '', //邮编
        address: '', //地址
        contacts: '', //	联系人
        mobile: '', //手机号
        tel: '', //固定电话
        email: '', //邮箱
        cnpj: '', //CNPJ(税号/身份证)
        mark: '', //唛头
        city: '', //城市名
        country: '', //国家名
      },
      // 付款后订单地址
      orderaddress: '',
      addressselect: 'Select',
      // 用户所有的收货地址
      userAddressList: {
        jinkoushang: [],
        shouhuodizhi: [],
      },
      // 选择的地址编号
      actNum: {
        jinkoushang: '',
        shouhuodizhi: '',
      },
      // 支付页面价格等数据
      datas: {
        sum_discount_price: 0,
        sum_price: 0,
        balance: 0,
      },
      notSufficientFundsDialogVisible: false,
      ruleForm: {
        pay_realname: '', //提交人
        pay_amount: '', // 付款金额
        pay_remark: '', // 备注
        pay_image_url: '', // 凭证图片地址
        pay_date: getCurrentDay(), // getCurrentDay()
      },

      protocol: false, //用户协议
      rules: {
        pay_realname: [
          {
            required: true,
            message: this.$fanyi('请填写付款人姓名'),
            trigger: 'blur',
          },
        ],
        pay_amount: [
          {
            required: true,
            message: this.$fanyi('请填写转账金额'),
            trigger: 'blur',
          },
          { validator: checkamount, trigger: 'blur' },
        ],
        pay_date: [
          {
            required: true,
            message: this.$fanyi('请选择日期'),
            trigger: 'change',
          },
        ],
        pay_remark: [
          {
            required: true,
            message: this.$fanyi('请填写备注信息'),
            trigger: 'blur',
          },
        ],
        pay_image_url: [
          {
            required: true,
            message: this.$fanyi('请上传付款证明'),
            trigger: 'change',
          },
        ],
      },
      // 全局加载控制
      loading: null,
      // 弹窗参数
      config: {
        top: '20vh',
        width: '500px',
        title: this.$fanyi('付款'),
        txtcenter: true,
        btnTxt: [this.$fanyi('支付'), this.$fanyi('取消')],
      },
      config1: {
        top: '20vh',
        width: '500px',
        title: this.$fanyi('是否登录'),
        btnTxt: [this.$fanyi('前往登录'), this.$fanyi('关闭')],
      },
      // 支付总价
      payAmount: 0,
      // 支付表单
    }
  },
  mounted() { },
  created() {
    this.bank = this.$fanyi('银行支付')
    // 首先判断是否是支付成功的回调 然后修改参数重新加载页面
    // 订单
    if (this.$route.query.order_sn) {
      if (this.$route.query.order_sn.split('@')[1] == 2) {
        this.$router.push({
          path: '/payment',
          query: {
            order_sn: this.$route.query.order_sn.split('@')[0],
            step: this.$route.query.order_sn.split('@')[1],
          },
        })
      }
    }

    //  配送单
    if (this.$route.query.porder_sn) {
      if (this.$route.query.porder_sn?.split('@')[1] == 2) {
        this.$router.push({
          path: '/payment',
          query: {
            porder_sn: this.$route.query.porder_sn.split('@')[0],
            step: this.$route.query.porder_sn.split('@')[1],
          },
        })
      }
    }

    // 如果是 paypal 支付成功的回调
    if (this.$route.query.step == 2) {
      // 如果有订单号 获取支付时传递的地址
      if (this.$route.query.order_sn) {
        this.getorderaddress()
      }
      this.step = 2
    }

    if (this.$route.query.order_sn && Number(this.$route.query.order_sn) != 0) {
      this.getgoodsData(this.$route.query.order_sn)
    }
    if (
      this.$route.query.porder_sn &&
      Number(this.$route.query.porder_sn) != 0
    ) {
      this.getpeisonggoodsData()
    }
    if (this.$route.query.porder_sn) {
      this.isPeiSongDan = true
    } else {
      this.isPeiSongDan = false
    }
    this.$api.EuropegetUserInfo().then((res) => {
      this.$store.commit('userData', res.data)
    })

    //如果 不是支付成功的回调
    if (!this.$route.query.step) {
      this.getData()
      this.getUserAddress(true)
    }
  },
  components: {
    HomePageTop,

    Dialog,
    stepShowVue,
    paySuccessInfoBlockVue,
    changeInformationVue,
    dizhiForm,
    jinkoushangForm,
    Paymentuseragreement,
  },
  methods: {
    // 上传成功
    handleAvatarSuccess(res, file) {
      this.pageLoading.close()

      if (res.code !== 0) {
        this.$message(res.msg)
        return
      }
      this.ruleForm.pay_image_url = res.data

      this.imageUrl = URL.createObjectURL(file.raw)
      this.$refs.ruleForm.clearValidate('pay_image_url')
    },
    // 上传之前
    beforeAvatarUpload(file) {
      const isJPG =
        ['image/jpeg', 'image/png', 'image/jpg'].indexOf(file.type) != -1

      if (!isJPG) {
        this.$message.error(this.$fanyi('请上传图片'))
        return isJPG
      }

      this.pageLoading = this.$loading({
        lock: true,
        text: this.$fanyi('图片上传中'),
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, .7)',
      })
    },

    //  获取订单地址
    async getorderaddress() {
      const { data } = await this.$api.orderAddress({
        order_sn: this.$route.query.order_sn,
      })
      this.orderaddress = data
    },
    // 根据id匹配选中的地址
    findValue(type) {
      if (type == 'jinkoushang') {
        let items = this.userAddressList[type].find(
          (item) => item.id == this.actNum.jinkoushang
        )
        if (items != undefined) {
          this.jinkoushang = items
          this.timer = new Date().getTime()
          this.$forceUpdate()
        }
      } else {
        let items = this.userAddressList[type].find(
          (item) => item.id == this.actNum.shouhuodizhi
        )
        if (items != undefined) {
          this.shouhuodizhi = items
          this.timer = new Date().getTime()

          this.$forceUpdate()
        }
      }
    },
    closePopover(val) {
      if (val) {
        this.Bankingmode = val
        this.payType = 0
      }

      this.$refs.bankTipRef.doClose()
    },
    //获取用户地址列表
    getUserAddress(moren) {
      this.$api.useraddressList().then((res) => {
        if (res.code != 0) return this.$message.error(res.msg)

        this.userAddressList.shouhuodizhi = res.data.consignee

        this.userAddressList.shouhuodizhi?.forEach((item, index) => {
          if (item.acquiescent == true) {
            this.addressselect = this.$fanyi('地址') + (index + 1)
            this.$refs.dizhiref
              ? (this.$refs.dizhiref.shouhuodizhiInfo = item)
              : ''
            this.$refs.dizhiref
              ? (this.$refs.dizhiref.shouhuodizhiIn = item)
              : ''
            this.$refs.dizhiref ? this.$refs.dizhiref.morendizhi() : ''
            this.shouhuodizhi = item
          }
        })
      })
    },

    // 银行支付判断
    Confirmthetransfer() {
      if (!this.onlinePayAgreementChecked) {
        this.$message.error(this.$fanyi('使用条款和隐私政策还没有被同意。'))
        return
      }
      // 进项判断是订单还是配送单校验表单
      if (this.$route.query.order_sn) {
        // 首先进行用户地址表单校验
        this.$refs.dizhiref.$refs.formRefdd.validate((valid) => {
          if (!valid) {
            this.payLoading = false
            this.$confirm(this.$fanyi('收货地址不完整，请查收'), this.$fanyi('提示'), {
              confirmButtonText: this.$fanyi('依然支付'),
              cancelButtonText: this.$fanyi('返回查看地址'),
              customClass: 'payment-confirm-dialog',
              type: 'warning'
            }).then(() => {

              this.$refs.ruleForm.validate((val) => {
                if (!val) {
                  this.payLoading = false
                  this.$message.warning(this.$fanyi('有必填项未填写'))
                } else {
                  // 校验通过往下走
                  this.onlineorderpayment = true
                }
              })
            }).catch(() => {

            });
          } else {
            // 表单通过在校验银行卡信息

            this.$refs.ruleForm.validate((val) => {
              if (!val) {
                this.payLoading = false
                this.$message.warning(this.$fanyi('有必填项未填写'))
              } else {
                // 校验通过往下走
                this.onlineorderpayment = true
              }
            })
          }
        })
      } else {
        // 配送单就直接校验信用卡信息
        this.$refs.ruleForm.validate((val) => {
          if (!val) {
            this.payLoading = false
            this.$message.warning(this.$fanyi('有必填项未填写'))
          } else {
            // 校验通过往下走
            this.onlineorderpayment = true
          }
        })
      }
    },

    //提交余额支付
    submitUserBalancePayOrder() {
      this.$api
        .orderBalancePayment({
          order_sn: this.$route.query.order_sn,
          coupon_ids: this.$route.query.coupon_ids,

          address: this.$refs.dizhiref.shouhuodizhiInfo,
        })
        .then((res) => {
          if (res.code != 0) return this.$message.error(res.msg)
          this.step = 2
          // 调用获取订单地址
          if (this.$route.query.order_sn) {
            this.getorderaddress()
          }
          this.userBalancePayDialogVisible = false
        })
    },
    // 提交订单在线支付
    Onlinepayment() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      this.$api
        .orderPingPongPayment({
          order_sn: this.$route.query.order_sn,
          coupon_ids: this.$route.query.coupon_ids,
          amount: this.payInput,
          address: this.$refs.dizhiref.shouhuodizhiInfo,
        })
        .then((res) => {
          if (res.code != 0) return this.$message.error(res.msg)
          loading.close()
          window.open(res.data)
          this.onlineorderpayment = false
        })
    },
    // 订单确认完成后银行支付银行支付
    async orderdebitcard() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      // (Fedwire)  2   (ACH) 3

      const res = await this.$api.orderBankPayment({
        order_sn: this.$route.query.order_sn,
        coupon_ids:
          this.$route.query.coupon_ids != 'undefined'
            ? this.$route.query.coupon_ids
            : '',
        bill_method:
          this.Bankingmode == 'Fedwire'
            ? 2
            : this.Bankingmode == 'ACH'
              ? 3
              : '',
        pay_realname: this.ruleForm.pay_realname,
        pay_amount: this.ruleForm.pay_amount,
        pay_image_url: this.ruleForm.pay_image_url,
        pay_date: this.ruleForm.pay_date,
        pay_remark: this.ruleForm.pay_remark,
        address: this.$refs.dizhiref.shouhuodizhiInfo,
      })

      loading.close()
      if (res.code != 0) return this.$message.error(res.msg)

      this.step = 2
      // 调用获取订单地址
      if (this.$route.query.order_sn) {
        this.getorderaddress()
      }

      this.onlineorderpayment = false
    },
    // 配送单完成后银行卡支付
    async deliverdebitcard() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      let id = ''
      if (this.$route.query.coupon_ids !== 'undefined') {
        id = this.$route.query.coupon_ids
      }
      const res = await this.$api.storagePOrderBankPayment({
        porder_sn: this.$route.query.porder_sn,
        coupon_ids: id ? id : '',
        bill_method: this.Bankingmode == 'Fedwire'
          ? 2
          : this.Bankingmode == 'ACH'
            ? 3
            : '',
        pay_realname: this.ruleForm.pay_realname,
        pay_amount: this.ruleForm.pay_amount,
        pay_image_url: this.ruleForm.pay_image_url,
        pay_date: this.ruleForm.pay_date,
        pay_remark: this.ruleForm.pay_remark,
      })

      loading.close()
      if (res.code != 0) return this.$message.error(res.msg)

      this.step = 2
      // 调用获取订单地址
      if (this.$route.query.order_sn) {
        this.getorderaddress()
      }

      this.onlineorderpayment = false
    },
    // 配送单余额支付
    Deliveryorderpayment() {
      this.$api
        .storagePOrderBalancePayment({
          porder_sn: this.$route.query.porder_sn,
          coupon_ids: this.$route.query.coupon_ids,
        })
        .then((res) => {
          if (res.code != 0) return this.$message.error(res.msg)
          // 如果moren参数为true，就遍历地址数据里的两个地址列表寻找默认地址

          this.step = 2
          this.userBalancePayDialogVisible = false
          this.flag = true
        })
    },
    // 提交配送单在线支付
    Deliveryorderonlinepayment() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      this.$api
        .storagepOrderPingPongPayment({
          porder_sn: this.$route.query.porder_sn,
          coupon_ids: this.$route.query.coupon_ids,
          amount: this.porderpayInput,
        })
        .then((res) => {
          if (res.code != 0) return this.$message.error(res.msg)

          loading.close()
          window.open(res.data)

          // this.step = 2
          this.onlineorderpayment = false
          // this.flag = true
        })
    },
    // 手动触发表单验证
    validateField(type, formRef) {
      if (this.ruleForm[type]) this.ruleForm[type] = this.ruleForm[type].trim()
      this.$refs[formRef].validateField(type, (v) => {
        if (v == '') {
        } else {
        }
        this.$forceUpdate()
      })
    },

    // 余额支付弹窗
    blanepayment() {
      if (!this.balancePayAgreementChecked) {
        this.$message.error(this.$fanyi('使用条款和隐私政策还没有被同意。'))
        return
      }


      // 如果是订单先进行表单校验
      if (this.$route.query.order_sn) {
        // 首先进行表单校验
        this.$refs.dizhiref.$refs.formRefdd.validate((valid) => {
          if (!valid) {
            this.payLoading = false

            this.$confirm(this.$fanyi('收货地址不完整，请查收'), this.$fanyi('提示'), {
              confirmButtonText: this.$fanyi('依然支付'),
              cancelButtonText: this.$fanyi('返回查看地址'),
              customClass: 'payment-confirm-dialog',
              type: 'warning'
            }).then(() => {
              console.log('212121');

              this.userBalancePayDialogVisible = true
            }).catch((e) => {


            });

          } else {
            this.userBalancePayDialogVisible = true
          }
        })
      }
    },
    //在线支付判断校验.配送单校验表单,订单无需校验表单
    showBankPayDialog() {

      if (!this.onlinePayAgreementChecked) {
        this.$message.error(this.$fanyi('使用条款和隐私政策还没有被同意。'))
        return
      }

      if (!this.$store.state.userInfo) {
        this.$refs.dialogzhifu.open(
          this.$fanyi('是否前往登录?'),
          () => {
            this.$store.commit('getactivePage', this.$route.fullPath)
            this.$fun.toPage('/login')
          },
          () => { }
        )
        return
      }
      if (this.$route.query.order_sn) {
        // 首先进行表单校验 通过后打开弹窗
        this.$refs.dizhiref.$refs.formRefdd.validate((valid) => {
          if (!valid) {
            this.payLoading = false
            this.$confirm(this.$fanyi('收货地址不完整，请查收'), this.$fanyi('提示'), {
              confirmButtonText: this.$fanyi('依然支付'),
              cancelButtonText: this.$fanyi('返回查看地址'),
              customClass: 'payment-confirm-dialog',
              type: 'warning'
            }).then(() => {
              // 订单在线支付
              if (this.payInput == '' && this.$route.query.order_sn)
                return this.$message.warning(this.$fanyi('有必填项未填写'))

              if (
                Number(this.payInput) < Number(this.datas.min_bank_pay_amount) &&
                this.$route.query.order_sn
              ) {
                this.$message.warning(
                  this.$fanyi('汇款金额需要大于当前不足的金额')
                )
                return
              }

              if (this.payType == 2) {
                this.Paypalpayment()
              } else {
                this.onlineorderpayment = true
              }
            }).catch(() => {

            });
          } else {
            // 订单在线支付
            if (this.payInput == '' && this.$route.query.order_sn)
              return this.$message.warning(this.$fanyi('有必填项未填写'))

            if (
              Number(this.payInput) < Number(this.datas.min_bank_pay_amount) &&
              this.$route.query.order_sn
            ) {
              this.$message.warning(
                this.$fanyi('汇款金额需要大于当前不足的金额')
              )
              return
            }

            if (this.payType == 2) {
              this.Paypalpayment()
            } else {
              this.onlineorderpayment = true
            }

          }
        })
      } else {
        // 配送单在线支付
        if (this.porderpayInput == '' && this.$route.query.porder_sn)
          return this.$message.warning(this.$fanyi('有必填项未填写'))
        if (
          Number(this.porderpayInput) <
          Number(this.datas.min_bank_pay_amount) &&
          this.$route.query.porder_sn
        )
          return this.$message.warning(
            this.$fanyi('汇款金额需要大于当前不足的金额')
          )
        if (this.payType == 2) {
          this.Paypalpayment()
        } else {
          this.onlineorderpayment = true
        }

      }
    },

    // 订单，配送单paypal支付

    async Paypalpayment() {
      let data = {
        coupon_ids: this.$route.query.coupon_ids,
        amount: 0,
      }
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      //如果是订单支付
      if (this.$route.query.order_sn) {
        data.order_sn = this.$route.query.order_sn
          ; (data.address = this.$refs.dizhiref.shouhuodizhiInfo),
            (data.amount = this.payInput)
        const res = await this.$api.orderPayPalPayment(data)
        loading.close()
        if (res.code != 0) return this.$message.error(res.msg)
        window.open(res.data.pay_url)

        this.onlineorderpayment = false
      }
      // 如果是配送单支付
      if (this.$route.query.porder_sn) {
        data.porder_sn = this.$route.query.porder_sn
        data.amount = this.porderpayInput
        const res = await this.$api.pOrderPayPalPayment(data)
        loading.close()
        if (res.code != 0) return this.$message.error(res.msg)
        window.open(res.data.pay_url)

        this.onlineorderpayment = false
      }
    },
    setNum(e) {
      this.payInput = e.replace(/([1-9]+\.[0-9]{2})[0-9]*/, '$1')
    },

    // 订单详情数据获取
    getgoodsData(order_sn, methods) {
      this.$api
        .orderDetail({
          order_sn: order_sn,
        })
        .then((res) => {
          let buydata = []
          res.data.detail.forEach((shopsItem) => {
            shopsItem.list.forEach((goodsItem) => {
              buydata.push(goodsItem)
            })
          })

          this.goodsdatas = res.data

          this.buyGoodsArr = buydata

          if (methods) {
            methods()
          }
        })
    },
    // 订单协议弹出层
    Userprotocol() {
      this.protocol = true
    },
    // 关闭订单协议弹框
    clonseUserprotocol(e) {
      this.protocol = e
      if (e) {
        // 说明是余额支付
        if (this.balance > 0 && this.balance - this.payAllCNPrice >= 0) {
          this.balancePayAgreementChecked = true
        }
        // 说明是在线支付
        if (this.payType == 1) {
          this.onlinePayAgreementChecked = true
        }

        this.protocol = false
      } else {
        this.protocol = e
      }
    },
    // 配送单详情数据获取
    getpeisonggoodsData() {
      this.$api
        .storageDeliverDetail({ porder_sn: this.$route.query.porder_sn })
        .then((res) => {
          let buydata = []

          res.data.result.porder_detail.forEach((shopsItem) => {
            buydata.push(shopsItem.order_detail)
          })

          res.data.result.orderAddress = {
            consignee_address: res.data.result.receive,
            importer_address: res.data.result.importer,
          }

          this.goodsdatas = res.data.result

          this.buyGoodsArr = buydata.flat()
        })
    },

    // 获取支付页面数据
    async getData() {
      // 配送单支付
      if (this.isPeiSongDan) {
        await this.$api
          .storagePOrderPayment({
            porder_sn: this.$route.query.porder_sn,
            coupon_ids: this.$route.query.coupon_ids,
          })
          .then((res) => {
            if (res.code != 0) return this.$message.error(this.$fanyi(res.msg))
            this.datas.min_bank_pay_amount = res.data.min_bank_pay_amount
            this.datas.sum_discount_price = res.data.porder_amount

            this.balance = res.data.balance

            if (Number(res.data.balance) > 0) {
              this.datas.balance = res.data.balance
            } else {
              this.datas.balance = 0
            }
            // this.ruleForm.pay_amount = this.$fun.ceil(res.data.porder_amount);

            this.payAll = res.data.porder_calculate
            this.payAllCNPrice = res.data.porder_amount
            this.ruleForm.pay_amount =
              this.balance > 0 && this.balance - this.payAllCNPrice > 0
                ? this.$fun.EURNumSegmentation(this.payAllCNPrice)
                : this.datas.min_bank_pay_amount

            this.porderpayInput = this.ruleForm.pay_amount
          })
      } else {
        // 订单支付
        let datas = {
          order_sn: this.$route.query.order_sn,
        }
        if (this.$route.query.coupon_ids) {
          datas.coupon_ids = this.$route.query.coupon_ids
        }
        await this.$api.orderPayment(datas).then((res) => {
          if (res.code != 0) return this.$message.error(this.$fanyi(res.msg))

          this.balance = res.data.balance
          this.datas = res.data

          // this.ruleForm.pay_amount = console.log(this.ruleForm.pay_amount);
          this.payAllCNPrice =
            res.data.sum_discount_price * this.$store.state.exchangeRate > 0 &&
              res.data.sum_discount_price * this.$store.state.exchangeRate < 0.01
              ? 0.01
              : this.$fun.ceil(
                res.data.sum_discount_price * this.$store.state.exchangeRate
              )

          this.payAll =
            res.data.sum_price * this.$store.state.exchangeRate > 0 &&
              res.data.sum_price * this.$store.state.exchangeRate < 0.01
              ? 0.01
              : this.$fun.ceil(
                res.data.sum_price * this.$store.state.exchangeRate
              )
          this.ruleForm.pay_amount =
            this.balance > 0 && this.balance - this.payAllCNPrice > 0
              ? this.$fun.EURNumSegmentation(this.payAllCNPrice)
              : this.datas.min_bank_pay_amount

          this.payInput = this.ruleForm.pay_amount
        })
      }
    },
    // 复制银行名称
    async Copytheaccountname(order_sn) {
      try {
        // 要复制的文本
        const textToCopy = 'Sameway Global Trade Investment Limited' + '   ' + order_sn

        // 将文本写入剪贴板
        await navigator.clipboard.writeText(textToCopy)

        // 通知用户复制成功
        this.$message.success('复制成功')
      } catch (err) {
        // 创建一个临时的textarea元素
        // 要复制的文本
        const textToCopy = 'Sameway Global Trade Investment Limited' + '   ' + order_sn
        const textarea = document.createElement('textarea')
        textarea.value = textToCopy

        // 添加到文档中
        document.body.appendChild(textarea)

        // 选择文本
        textarea.select()

        // 复制选中的文本到剪贴板
        document.execCommand('copy')

        // 移除临时的textarea元素
        document.body.removeChild(textarea)
        // 通知用户复制成功
        this.$message.success('复制成功')
      }
    },
    async copyTextToClipboard($event) {
      // 获取事件触发对象

      try {
        // 要复制的文本
        const textToCopy = $event.currentTarget.parentElement.textContent

        // 将文本写入剪贴板
        await navigator.clipboard.writeText(textToCopy)

        // 通知用户复制成功
        this.$message.success('复制成功')
      } catch (err) {
        // 创建一个临时的textarea元素
        // 要复制的文本
        const textToCopy = $event.currentTarget.parentElement.textContent
        const textarea = document.createElement('textarea')
        textarea.value = textToCopy

        // 添加到文档中
        document.body.appendChild(textarea)

        // 选择文本
        textarea.select()

        // 复制选中的文本到剪贴板
        document.execCommand('copy')

        // 移除临时的textarea元素
        document.body.removeChild(textarea)
        // 通知用户复制成功
        this.$message.success('复制成功')
      }
    },
  },
}
</script>

<style lang="scss">
.payment-confirm-dialog {
  width: 500px;
  border-radius: 8px;

  .el-message-box__content {
    height: 78px;
    display: flex;
    align-items: center;
    justify-content: center
  }

  .el-message-box__btns {
    margin-bottom: 10px;
    text-align: center;

    .el-button {
      height: 38px;


      &:last-child {
        background-color: #FF730B;

        &:hover {
          background-color: #FF730B;

        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
@import '../../css/mixin.scss';


.Bankingmode {
  p {
    width: 200px;
    height: 44px;
    border-radius: 4px;
    font-family: Roboto, Roboto;
    font-weight: 400;
    font-size: 16px;
    color: #222222;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    // &:nth-child() {}

    // &:last-child {}
    &:hover {
      background: #fff2e8;
      border-radius: 4px;
      font-weight: bold;
      font-size: 16px;
      color: #ff730b;
      cursor: pointer;
    }
  }
}


.page {
  padding-bottom: 100px;
  background: #f6f6f6;
}

/deep/.el-checkbox__inner::after {
  left: 5px !important;
}

/deep/ .el-dialog__title {
  display: inline-block;

  margin-top: 15px;
  margin-left: 25px;
}

/deep/ .el-dialog__header,
/deep/ .el-dialog__body {
  background: #ffffff;
  border-radius: 6px !important;
  padding: 0;
  min-width: 350px;
}

/deep/ .el-dialog__footer {
  padding: 0 30px 30px;
}

.Natixis {
  display: flex;
  padding-left: 190px;
  margin-top: 30px;

  div {
    width: 76px;
    height: 40px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:first-child {
      width: 76px;
      height: 40px;
      border-radius: 4px;
      border: 1px solid #e2e2e2;
      margin-right: 10px;
      font-size: 14px;
      font-family: Roboto-Medium, Roboto;
      font-weight: 500;
      color: #222222;
    }

    &:last-child {
      width: 82px;
      height: 40px;
      margin-left: 0px !important;
      background: #ff730b;
      border-radius: 4px;
      font-size: 14px;
      font-family: Roboto-Medium, Roboto;
      font-weight: 500;
      color: #ffffff;
      line-height: 20px;
    }
  }
}

.rujinjin {
  height: 50px;
  width: 100%;
}

/deep/.el-dialog {
  border-radius: 6px;
}

.avatar-uploader-icon {
  font-size: 24px;
  color: #abb0b8;
  width: 80px !important;
  height: 80px !important;
  line-height: 80px;
  text-align: center;
}

/deep/.avatar {
  width: 80px !important;
  height: 80px !important;
  display: block;
}

/deep/ .avatar-uploader {
  margin-top: 40px;
  width: 80px;
  height: 80px;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #dedede;
}

.couponHeader {
  justify-content: space-between;
  //padding:0 10px;
  border-bottom: 1px solid #f5f5f5;
  height: 64px;
  margin: 0 20px;

  div:first-child {
    color: #222222;
    font-size: 18px;
  }

  .el-icon-close {
    color: #000;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
  }
}

.remindBtnList {
  margin-top: 30px;
  justify-content: center;

  div {
    width: 120px;
    height: 40px;
    background: #ff730b;
    cursor: pointer;
    line-height: 40px;
    text-align: center;
    color: #fff;

    border-radius: 4px;
    border: 1px solid #ff730b;
  }

  div:first-child {
    margin-right: 40px;
  }

  div:last-child {
    background: #fff;
    color: #222222;
    border: 1px solid #e2e2e2;
  }
}

/deep/ .el-dialog__header {
  height: 0px;
}

.couponFooter {
  padding: 32px 60px 30px;
  padding-right: 20px;
  padding-left: 20px;

  .remindHeaderContainer {
    width: 300px;
    padding: 6px !important;
    // background-color: pink;

    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }

    div {
      color: #222222;
    }
  }
}

// 选项框样式
/deep/ .diZhiXuanZe {
  .el-input__inner {
    width: 360px;
    height: 48px;
    background: #f9f9f9;
    border: 1px solid #e2e2e2;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 400;
  }
}

.el-select-dropdown__item {
  height: 30px !important;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  // border-bottom: solid 1px #e2e2e2;
  margin: 10px 20px;
  padding: 0;

  &:hover,
  &.hover {
    background: #fff2e8;
    border-radius: 4px;
  }

  &.selected {
    color: #ff730b;

    &:after {
      content: '✓';
      margin-left: auto;
    }
  }
}

.payPage {
  width: 1400px;
  margin: 80px auto 0;
}

.payStep1 {
  display: flex;
  align-items: flex-start;

  .payLeftCon {
    width: 800px;

    .addressBox {
      border: 1px solid #e2e2e2;
      margin-bottom: 20px;
      padding: 20px 30px 20px 30px;
      width: 800px;
      height: 426px;
      background: #ffffff;
      border-radius: 6px !important;
      border: 1px solid #e2e2e2;
      overflow: hidden;
    }

    .linkman {
      width: 100%;
      // height: 466px;
      background: #ffffff;
      // border: 1px solid #e3e3e3;
      border-radius: 0;
      //border-radius: 4px;

      &.shouhuo {
        padding-top: 20px;
      }

      // margin-bottom: 40px;
      h1 {
        font-size: 18px;
        font-weight: 600;
        color: #2f2f2f;
        margin-bottom: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  .payRightCon {
    width: 560px;
    min-height: 539px;
    background: #ffffff;
    border-radius: 6px;
    border: 1px solid #e2e2e2;
    //border-radius: 4px;
    padding: 40px;
    margin: 0 0 0 40px;

    .fukuan {
      height: 18px;
      font-size: 18px;

      font-weight: 600;
      color: #2f2f2f;
      line-height: 18px;
      margin-bottom: 30px;
    }

    // 余额支付css
    .balance {
      width: 100%;

      .top-title {
        font-size: 18px;
        font-family: PingFang-SC-Semibold, PingFang-SC;
        font-weight: 600;
        color: #ff730b;
        line-height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;

        span {
          width: 120px;
          height: 3px;
          background: #ff730b;
          position: absolute;
          bottom: -10px;
        }

        margin-bottom: 10px;
      }

      .oeder-box {
        padding-top: 30px;
        width: 100%;
        height: 140px;

        .order,
        .jine,
        .useblane,
        .paymentblane {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }
      }

      .order {
        margin-bottom: 26px;

        span {
          &:first-child {
            font-size: 16px;
            font-family: PingFang-SC-Regular, PingFang-SC;
            font-weight: 400;
            color: #222222;
            line-height: 24px;
          }

          &:last-child {
            font-size: 16px;
            font-family: Roboto-Regular, Roboto;
            font-weight: 400;
            color: #ff730b;
            line-height: 24px;
          }
        }
      }

      .jine {
        span {
          &:first-child {
            font-size: 16px;
            font-family: PingFang-SC-Regular, PingFang-SC;
            font-weight: 400;
            color: #222222;
            line-height: 24px;
          }

          &:last-child {
            font-size: 24px;
            font-family: Roboto-Bold, Roboto;
            font-weight: bold;
            color: #ff730b;
            line-height: 36px;
          }
        }
      }

      .useblane {
        margin-bottom: 26px;

        span {
          &:first-child {
            font-size: 16px;
            font-family: PingFang-SC-Regular, PingFang-SC;
            font-weight: 400;
            color: #222222;
            line-height: 24px;
          }

          &:last-child {
            font-size: 16px;
            font-family: Roboto-Regular, Roboto;
            font-weight: 400;
            color: #222222;
          }
        }
      }

      .paymentblane {
        span {
          &:first-child {
            font-size: 16px;
            font-family: PingFang-SC-Regular, PingFang-SC;
            font-weight: 400;
            color: #222222;
            line-height: 24px;
          }

          &:last-child {
            font-size: 24px;
            font-family: Roboto-Bold, Roboto;
            font-weight: bold;
            color: #222222;
          }
        }
      }

      .balancePayBtn {
        margin-top: 40px;
        width: 480px;
        height: 56px;
        background: #ff730b;
        border-radius: 6px;
        color: #fff;
        line-height: 56px;
        text-align: center;
        cursor: pointer;
      }

      .bankPayAgreementContainer {
        margin-top: 10px;
        width: 480px;
        display: flex;

        // /deep/ .el-checkbox {
        //   width: 480px;
        //   height: 50px;
        // }
        /deep/ .el-checkbox__inner {
          margin-top: 2px;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          margin-right: 2px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        p {
          font-size: 14px;
          font-family: PingFang-SC-Regular, PingFang-SC;
          font-weight: 400;
          color: #999999;
          margin-left: 2px;

          span {
            color: #ff730b;
            text-decoration: underline;
          }
        }
      }
    }

    .payTypeChiose {
      width: 480px;
      height: 68px;
      border-radius: 0;
      //border-radius: 6px;
      position: relative;

      .allPayType {
        display: flex;
        justify-content: center;
        align-items: center;

        >div {
          flex: 1;
          height: 68px;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1;
          font-size: 20px;
          font-weight: 400;
          cursor: pointer;

          &.active {
            color: #ff730b;
            font-weight: 600;
          }
        }
      }

      .chioseBlock {
        width: 240px;
        height: 48px;
        border-radius: 0;

        border-bottom: 3px solid #ff730b;
        position: absolute;
        top: 8px;
        left: 60px;
        transition: 0.3s;

        &.xinyongka {
          left: 38%;
        }

        &.zaixianzhufu {
          left: 50%;
        }

        &.paypal {
          left: 0% !important;
        }
      }
    }

    .totalPrice {
      height: 93px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;
      border-bottom: #222 solid 1px;

      span {
        font-size: 16px;
        font-weight: 400;

        &.zongJia {
          font-size: 24px;
          font-weight: bold;
        }
      }
    }

    // 银行卡支付
    .debitcard {

      // 银行卡
      .Rabobank {
        .top-box {
          padding-top: 25px;
          height: 140px;
          width: 100%;
          border-bottom: 1px dashed #e2e2e2;

          .useblane {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 26px;

            span {
              &:first-child {
                font-size: 16px;
                font-family: PingFang-SC-Regular, PingFang-SC;
                font-weight: 400;
                color: #222222;
              }

              &:last-child {
                font-size: 16px;
                font-family: Roboto-Regular, Roboto;
                font-weight: 400;
                color: #ff730b;
              }
            }
          }

          .Lowest {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            span {
              &:first-child {
                font-size: 16px;
                font-family: PingFang-SC-Regular, PingFang-SC;
                font-weight: 400;
                color: #222222;
              }

              &:last-child {
                font-size: 24px;
                font-family: Roboto-Bold, Roboto;
                font-weight: bold;
                color: #ff730b;
              }
            }
          }
        }

        // 银行卡区域
        .content {
          width: 100%;
          padding-top: 30px;
          border-bottom: 1px dashed #e2e2e2;
          padding-bottom: 10px;

          .pic-box {
            width: 480px;
            height: 80px;
            background: #f6f6f6;
            border-radius: 6px;
            border: 1px solid #dedede;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 30px;

            // img {
            //   // width: 134px;
            //   // height: 30px;
            // }
          }

          .operationshow {
            width: 100%;
            height: 218px;
            padding-top: 30px;
            display: flex;

            padding-left: 20px;

            .left {
              width: 40px;
              position: relative;
              height: 154px;
              margin-right: 20px;

              .one,
              .two {
                width: 40px;
                height: 40px;
                background: #ffffff;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                border: 1px solid #e2e2e2;
              }

              .two {
                margin-top: 74px;
              }

              span {
                position: absolute;
                left: 50%;
                width: 1px;
                top: 50px;
                transform: translateX(-50%);
                height: 54px;
                background-color: #e2e2e2;
              }
            }

            .right {
              .box {
                margin-bottom: 60px;

                h3 {
                  font-size: 16px;
                  font-family: PingFang-SC-Regular, PingFang-SC;
                  font-weight: 400;
                  color: #222222;
                  margin-bottom: 10px;
                }

                p {
                  font-size: 14px;
                  font-family: PingFang-SC-Regular, PingFang-SC;
                  font-weight: 400;
                  color: #666666;
                  line-height: 20px;
                }
              }
            }
          }

          /deep/ .el-dialog {
            width: 480px;
            height: 333px;
            background: #ffffff;
            box-shadow: 0px 0px 10px 0px rgba(61, 61, 61, 0.1);
            border-radius: 6px;
          }

          /deep/ .el-dialog__header {
            width: 100%;
            height: 54px;
            border-bottom: 1px solid #f5f5f5;

            /deep/.el-dialog__title {
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #222222;
            }
          }

          .Attninfo {
            h2 {
              font-size: 18px;
              font-family: PingFang-SC-Semibold, PingFang-SC;
              font-weight: 600;
              color: #222222;
              margin-bottom: 10px;
            }

            p {
              font-size: 14px;
              font-family: PingFang-SC-Regular, PingFang-SC;
              font-weight: 400;
              font-size: 16px;
              font-family: PingFang-SC-Regular, PingFang-SC;
              font-weight: 400;
              color: #222222;
              line-height: 24px;
              margin-bottom: 10px;
            }

            .operation {
              display: flex;
              align-items: center;
              font-size: 14px;
              font-family: PingFang-SC-Regular, PingFang-SC;
              font-weight: 400;
              color: #ff730b;
              line-height: 20px;
              margin-bottom: 30px;

              img {
                margin-right: 10px;
              }
            }

            .ordersn {
              width: 480px;
              height: 60px;
              background: #fff8f3;
              border-radius: 6px;
              border: 1px solid #ff730b;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-bottom: 30px;

              img {
                width: 20px;
                height: 20px;
                margin-right: 10px;
              }

              span {
                height: 25px;
                line-height: 25px;
              }
            }

            .title {
              font-weight: 400;
              font-size: 16px;
              color: #666666;
              line-height: 20px;
              line-height: 24px;
              margin-bottom: 10px;
            }

            .text {
              margin-bottom: 20px;
              font-size: 16px;
              font-family: PingFang-SC-Regular, PingFang-SC;
              font-weight: 400;
              display: flex;
              align-items: center;
              color: #222222;

              img {
                margin-left: 10px;

                &:hover {
                  cursor: pointer;
                }
              }
            }
          }
        }

        // 表单区域
        .ruleform {
          padding-top: 30px;

          /deep/ .el-form-item__label {
            font-size: 16px;
            font-family: PingFang-SC-Regular, PingFang-SC;
            font-weight: 400;
            color: #222222;
            line-height: 24px;
            margin-bottom: 10px;
          }

          /deep/ .el-input__inner {
            width: 480px;
            height: 48px;
            background: #ffffff;
            border-radius: 6px;
            border: 1px solid #e2e2e2;
          }
        }

        .date {
          // background-color: pink;

          /deep/ .el-form-item__label {
            display: inline-block;
          }

          /deep/ .el-date-editor {
            width: 480px;
            height: 48px;
            background: #ffffff;
            border-radius: 6px;

            // border: 1px solid #e2e2e2;
          }

          /deep/ .el-input__inner {
            padding-left: 15px !important;
          }
        }

        /deep/.el-icon-date {
          display: none;
        }

        .Confirmthetransfer {
          width: 480px;
          height: 56px;
          margin-top: 40px;
          background: #ff730b;
          border-radius: 6px;
          font-size: 16px;
          font-family: MicrosoftYaHei;
          color: #ffffff;
          line-height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .bankPayAgreementContainer {
        margin-top: 10px;
        width: 480px;
        display: flex;

        // /deep/ .el-checkbox {
        //   width: 480px;
        //   height: 50px;
        // }
        /deep/ .el-checkbox__inner {
          margin-top: 2px;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          margin-right: 2px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        p {
          font-size: 14px;
          font-family: PingFang-SC-Regular, PingFang-SC;
          font-weight: 400;
          color: #999999;
          margin-left: 2px;

          span {
            color: #ff730b;
            text-decoration: underline;
          }
        }
      }

      // 在线
      .creditCardPay {
        .onlinePayContainer {
          .balancePayHeaderContainer {
            border-bottom: 1px dashed #e2e2e2;

            .balancePayHeaderTopContainer,
            .balancePayHeaderBottomContainer {
              width: 100%;
              justify-content: space-between;
              margin-bottom: 25px;

              div:first-child {
                font-weight: 400;
                color: #222222;
              }
            }

            .balancePayHeaderTopContainer {
              font-size: 16px;
              margin-bottom: 26px;

              div:last-child {
                color: #222222;
              }
            }

            .selectPayModeText {
              margin-bottom: 10px;
            }

            .zhifufangshi {
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top: 30px;
              width: 480px;
              height: 80px;
              background: #f6f6f6;
              border-radius: 6px;
              border: 1px solid #dedede;
            }

            .paypal {
              a {
                color: #2790C3;
                text-decoration: underline;
                margin-top: 20px;
                cursor: pointer;
                display: flex;
                align-items: center;
                height: 20px;
              }

              img {
                margin-right: 5px;
              }
            }

            .Paymentprompt {
              margin-top: 30px;

              p {
                line-height: 20px;
                margin-bottom: 5px;
              }
            }

            img {
              width: 150px;
              height: 78px;
            }

            .Airwallex {
              width: 120px;
              height: 48px;
              background: #ffffff;
              border-radius: 6px;
              border: 1px solid #e2e2e2;
              margin-right: -90px;
              padding-left: 20px;
              padding-top: 12px;

              .zheng {
                width: 30px;
                height: 30px;
              }
            }

            .bankListContainer {
              margin-bottom: 20px;

              .selectBankContainer {
                background: #fff1e6;
                border: 1px solid #ffbe8e;
              }

              div {
                width: 230px;
                height: 64px;
                background: #f9f9f9;
                border: 1px solid #e2e2e2;
                margin-right: 20px;
                justify-content: center;
                cursor: pointer;

                // img {
                //   width: 156px;
                //   height: 33px;
                // }
              }
            }

            .payPriceContainer {
              // background-color: pink;
              margin-top: 30px;
              width: 100%;

              .payment {
                font-size: 16px;
                font-family: PingFang-SC-Regular, PingFang-SC;
                font-weight: 400;
                color: #222222;
                margin-bottom: 10px;
              }

              span {
                font-size: 16px;
                font-family: PingFang-SC-Regular, PingFang-SC;
                font-weight: 400;
                color: #e74c3c;
                padding-top: 5px;
                margin-right: 5px;
              }

              /deep/.el-input__inner {
                width: 480px;
                height: 48px;
                background: #ffffff;
                border-radius: 6px;
                border: 1px solid #e2e2e2;
                margin-bottom: 0 !important;
              }
            }
          }

          .balancePayBtn {
            margin-top: 40px;
            width: 480px;
            height: 56px;
            background: #ff730b;
            border-radius: 6px;
            color: #fff;
            line-height: 56px;
            text-align: center;
            cursor: pointer;
          }

          .bankPayAgreementContainer {
            margin-top: 10px;
            width: 480px;
            display: flex;

            /deep/ .el-checkbox__inner {
              margin-top: 2px;
              width: 16px;
              height: 16px;
              border-radius: 50%;
              border-radius: 50%;

              margin-right: 2px;
              display: flex;
              justify-content: center;
              align-items: center;

              .el-checkbox__inner::after {
                left: 5px !important;
              }
            }

            p {
              font-size: 14px;
              font-family: PingFang-SC-Regular, PingFang-SC;
              font-weight: 400;
              color: #999999;
              margin-left: 2px;

              span {
                color: #ff730b;
                text-decoration: underline;
              }
            }
          }

          /deep/ .el-input {
            width: 280px;
            height: 48px;
            background: #ffffff;
            border-radius: 6px;

            .el-input__inner {
              width: 280px;
              height: 48px;
              background: #ffffff;
              border-radius: 6px;
            }
          }
        }

        .cardIcon {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 30px;

          >div {
            width: 230px;
            height: 72px;
            border: 1px solid #e8e8e8;
            border-radius: 0;
            //border-radius: 6px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 178px;
              height: 47px;
            }
          }
        }

        .xuanze {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 25px;
          margin-bottom: 10px;

          span {
            font-size: 16px;
            font-weight: 600;
            color: #2f2f2f;
            line-height: 25px;
          }

          .cardiconBox {
            display: flex;
            align-items: center;

            >div {
              width: 50px;
              height: 25px;
              border: 1px solid #e8e8e8;
              border-radius: 0;
              //border-radius: 6px;
              margin-left: 6px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }

        .cardPayBtn {
          width: 480px;
          height: 56px;
          background: #ff730b;
          border: 1px solid #e8e8e8;
          border-radius: 0;
          //border-radius: 6px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 20px;

          color: #ffffff;

          &:hover {
            opacity: 0.7;
          }
        }

        .zhenCe {
          display: flex;
          align-items: flex-start;

          /deep/ .el-checkbox__input {
            height: 20px;

            .el-checkbox__inner {
              border-radius: 50%;
              width: 20px;
              height: 20px;

              &:after {
                transform: rotate(45deg) scaleY(1) translate(4px, 0px);
              }
            }
          }

          span {
            display: block;
            margin-left: 12px;
            width: 396px;
            font-size: 16px;

            font-weight: 400;
            color: #2f2f2f;
            line-height: 24px;
            margin-top: -2px;
          }
        }
      }
    }
  }

  .wuLiuZuiZhongBaoJia {
    width: 800px;
    height: 386px;
    background: #ffffff;
    border: 1px solid #e3e3e3;

    border-radius: 6px;
    padding: 40px;
    margin-bottom: 20px;

    h1 {
      font-size: 18px;
      font-weight: 600;
      color: #2f2f2f;
      line-height: 18px;
      margin-bottom: 20px;
    }

    h2 {
      height: 15px;
      font-size: 14px;
      font-weight: 400;
      color: #2f2f2f;
      line-height: 14px;
      margin-bottom: 20px;
    }

    .priceBox {
      .priceOpt {
        height: 53px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: #ededf5 solid 1px;

        label {
          font-size: 14px;
          font-weight: 400;
          color: #868686;
          line-height: 16px;
        }

        .PriceOuYuan {
          font-size: 16px;
          font-weight: bold;
          color: #2f2f2f;
          line-height: 16px;
        }
      }
    }

    .shouldPayPrice {
      padding: 40px 0;
      padding-bottom: 0px;
      font-size: 18px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: bold;
      color: #272727;
      line-height: 18px;
    }
  }
}

.payStep2 {
  padding-bottom: 56px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .payStep2Left {
    width: 800px;

    .successMesBox {
      width: 800px;
      height: 518px;
      background: #ffffff;
      border-radius: 6px;
      border: 1px solid #e2e2e2;
      background: #ffffff;
      border-radius: 6px;
      border: 1px solid #e2e2e2;
      //border-radius: 4px;
      padding-top: 60px;
      margin-bottom: 20px;

      .successMesBoxCon {
        width: 560px;
        margin: 0 auto 20px;
        text-align: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .succTitle {
          margin-bottom: 30px;
          margin: auto;

          img {
            width: 80px;
            height: 80px;
            margin: auto;
          }

          .over {
            margin-top: 30px;
            font-size: 24px;
            font-family: PingFang-SC-Semibold, PingFang-SC;
            font-weight: 600;
            color: #222222;
            margin-bottom: 40px;
          }
        }

        .succBody {
          margin: -5px 0 35px 0;
          font-size: 16px;
          font-weight: 400;
          color: #2f2f2f;
          line-height: 26px;
          text-align: center;
        }

        .successBtn {
          min-width: 200px;
          height: 60px;
          background: #ff730b;
          border-radius: 4px;
          background: #ff730b;

          padding: 0 32px;
          //border-radius: 6px;
          font-size: 16px;
          display: flex;
          justify-content: center;
          align-items: center;

          font-weight: 400;
          color: #ffffff;
          line-height: 16px;
        }
      }
    }

    &.ispeison {
      margin: 0 auto;
    }
  }

  .payStep2Right {
    padding: 40px 30px;
    width: 560px;
    height: 556px;
    background: #ffffff;
    border: 1px solid #e3e3e3;
    border-radius: 0;

    //border-radius: 4px;
    .nextTitle {
      font-size: 18px;
      font-weight: 600;
      color: #2f2f2f;
      line-height: 18px;
      margin-bottom: 10px;
    }

    .nextStepBox {
      .nextStepOpt {
        border-bottom: #e7e7e7 1px solid;

        &:last-child {
          border-bottom: none;
        }

        height: 121px;
        padding: 29px 0 30px;
        display: flex;
        align-items: flex-start;

        .nextStepNum {
          width: 41px;
          height: 41px;
          border: 1px solid #e7e7e7;
          border-radius: 50%;
          font-size: 16px;
          font-weight: 400;
          display: flex;
          justify-content: center;
          margin-right: 20px;
          align-items: center;
        }

        .nextStepCon {
          h4 {
            font-size: 16px;
            font-weight: 400;
            color: #21213b;
            line-height: 17px;
            margin-bottom: 10px;
            margin-top: -1px;
          }

          p {
            width: 436px;
            font-size: 14px;

            font-weight: 400;
            color: #66667e;
            line-height: 21px;
            margin-top: -3.5px;
          }
        }
      }
    }
  }
}

/deep/ .is-error .el-input__inner {
  border-color: red !important;
}

/deep/ .is-error .el-upload {
  border-color: red !important;
}

.goodsBox {
  width: 800px;
  height: 392px;
  background: #ffffff;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #e2e2e2;
  //border-radius: 4px;
  padding: 30px 30px;
  position: relative;
  transition: 0.3s;
  overflow: hidden;

  .dingdan {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px !important;
  }

  &.margin {
    // border: none;
    height: 300px;
    min-height: 300px;
  }

  &.marginhave {
    margin-left: -30px;
    margin-top: -20px;
    margin-bottom: -20px;
    padding-top: 0;
    border: none;
    height: 222px;
    min-height: 222px;
  }

  h1 {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 600;
    color: #2f2f2f;
    line-height: 38px;
  }

  .goodsBoxtishi {
    display: block;
    font-size: 16px;
    height: 17px;
    font-weight: 400;
    color: #2f2f2f;
    line-height: 17px;
    margin-bottom: 10px;
  }

  .goodsArr {
    .goodsTr {
      display: flex;
      align-items: center;

      &.goodsOpt {
        padding-bottom: 40px;
      }

      .goodsTd {
        font-size: 14px;
        font-size: 14px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #222222;
        line-height: 29px;
        flex: 1;

        &.goodshe {
          height: 53px;

          display: flex;
          // background-color: pink;
          align-items: center;
        }

        &.goodsBo {
          height: 85px;
          // background-color: yellow;
        }

        &.goodsPrice {
          display: flex;
          justify-content: center;
          // text-align: center;
          flex: 0 0 184px;
          // background-color: blue;
          align-items: center;

          &.goodsBo {
            background: #fff5ee;
            // padding-top: 15px;

            p {
              font-size: 16px;
              font-weight: 600;
              color: #2f2f2f;
              text-align: center;
              line-height: 21px;
            }
          }
        }

        &.goodsNumber {
          width: 200px !important;
          padding-left: 180px;
          justify-content: center;
          // align-items: center !important;
          display: flex;
          // padding-right: -50px !important;
          text-align: center;
          // margin-left: 20px;
          // background-color: blue;

          &.goodsBo {
            font-size: 12px;
            font-weight: 400;
            line-height: 12px;
            text-align: center;
            justify-content: center;
            padding-left: 0 !important;
            align-items: center !important;
            // background-color: pink;
          }
        }

        &.goodsName {
          display: flex;
          // background-color: yellow;

          /deep/ .el-image {
            $imgSize: 80px;
            width: $imgSize;
            height: $imgSize;
            transition: 0.3s;
            text-align: center;
            margin-right: 50px;

            i {
              font-size: $imgSize/4;
              line-height: $imgSize;
            }
          }

          .goodsTitle {
            width: 220px;
            height: 90px;
            min-width: 85px;
            font-size: 12px;
            font-weight: 400;
            position: relative;
            // background-color: pink;
            top: -5px;
            color: #2f2f2f;
            line-height: 21px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 16px;
            font-family: PingFang-SC-Regular, PingFang-SC;
            font-weight: 400;
            color: #222222;
            line-height: 24px;
            // overflow: auto;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
  }

  .showAllRow {
    width: 800px;
    height: 48px;
    background: #ffffff;
    border-radius: 0px 0px 6px 6px;
    border: 1px solid #e2e2e2;
    //border-radius:0;: 0px 0px 4px 4px;
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    background: #f9f9f9;
    border: 1px solid #e2e2e2;

    .jiahao {
      width: 16px;
      height: 16px;
      margin-right: 32px;
      cursor: pointer;
    }
  }

  &.GoodsArrAll {
    min-height: 392px !important;
    height: unset !important;
    overflow: auto !important;
  }
}

.jinkoushang {
  display: flex;
  justify-content: space-between;

  .jinkoushanginfo {
    div {
      font-size: 16px;
      font-weight: 400;
      color: #2f2f2f;
      line-height: 24px;
    }

    .jinkoushanginfoLabel {
      margin-right: 10px;

      color: #666666;
      width: 140px;
      text-align: right;
    }

    .jinkoushanginfoValue {
      text-align: left;
    }
  }
}
</style>
